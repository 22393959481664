import { Component, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { AccountsSubCodes } from "../../models";
import { ControlRoutes } from "../../routes";
import { BankingService } from "../../services";
import { MasonryService } from "src/app/shared/services";

@Component({
  selector: "app-overdrawn-account-tile",
  templateUrl: "./overdrawn-account-tile.component.html",
  styleUrls: ["./overdrawn-account-tile.component.scss"],
})
export class OverdrawnAccountTileComponent implements OnInit {
  toggle = false;
  disabled = false;
  currentPage = 1;
  pageSize = 5;

  constructor(
    public _bankingService: BankingService,
    private _router: Router,
    private _masonry: MasonryService
  ) {}

  ngOnInit(): void {
    this._bankingService.getOverDrawnAccountDto(0);
  }

  onRowClick(item: AccountsSubCodes): void {
    this._router.navigate([ControlRoutes.Clients, item.CLIENT]);
  }

  onPageChange(event: any): void {
    this.currentPage = event;
    this._masonry.updateLayout();
  }
}
