import { Component } from "@angular/core";
import { AuthService } from "src/app/login/services/auth.service";
import { ControlRoutes } from "../../routes";
import { LocalStorageService } from "../../services";

@Component({
  selector: "app-h-menu",
  templateUrl: "./h-menu.component.html",
  styleUrls: ["./h-menu.component.scss"],
})
export class HMenuComponent {
  readonly ControlRoutes = ControlRoutes;

  constructor(
    private authHTTP: AuthService,
    private storage: LocalStorageService
  ) {}

  logout(): void {
    this.storage.setItem("lastPage", "/");
    this.authHTTP.logout();
  }
}
