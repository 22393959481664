export enum CHART_COLORS {
  lightBlue = "#4D85B0",
  middBlue = "#2F5B88",
  darkBlue = "#051A3F",
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function currencyLabel(context: any): string {
  let label = context.dataset.label || "";

  if (label) {
    label += ": ";
  }

  if (!!context.parsed.y) {
    label += formatter.format(context.parsed.y);
  } else {
    label += formatter.format(context.parsed);
  }

  return label;
}

export function buildHttpOptions(params?: any): any {
  const httpOptions = {
    ...params,
  };

  return httpOptions;
}
