<div class="d-flex flex-row flex-nowrap" style="height: 100%">
  <!-- Hidden only on xs -->
  <div class="d-none d-lg-block">
    <app-sidebar-menu></app-sidebar-menu>
  </div>

  <div class="d-flex flex-column flex-grow-1">
    <div class="d-block d-lg-none">
      <app-h-menu></app-h-menu>
    </div>
    <app-header-bar></app-header-bar>
    <main class="d-flex flex-column main-container">
      <router-outlet></router-outlet>
      <p class="custom-footer text-center text-muted mt-auto mb-1 pt-3 px-3">
        &copy; {{ todayDate | date : "yyyy" }} Zenith Information Systems, Inc.
        All Rights Reserved.
      </p>
    </main>
  </div>
</div>
