import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { BankingService, MockedDataService } from "../../services";
import { Code } from "../../models";

@Component({
  selector: "app-tile-debt",
  templateUrl: "./tile-debt.component.html",
  styleUrls: ["./tile-debt.component.scss"],
})
export class TileDebtComponent implements OnInit, OnChanges {
  @Input() clientId = "";
  items: Code[] = [];
  isLoading: boolean = true;

  balance = 0;

  constructor(
    private _dataMock: MockedDataService,
    private _bankingService: BankingService
  ) {}

  ngOnInit(): void {
    this._getData();
  }

  ngOnChanges(): void {
    this.isLoading = true;

    this._getData();
  }
  private _getData(): void {
    this._bankingService.getDebitData(this.clientId).subscribe({
      next: (data) => {
        this.items = data;
      },
      complete: () => {
        this.isLoading = false;

        this._calculateTotal();
      },
    });
  }

  private _calculateTotal(): void {
    this.balance = this.items.reduce((prev, current) => {
      return prev + Number(current.ENDING_BALANCE);
    }, 0);
  }
}
