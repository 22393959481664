<div style="width: inherit">
  <router-outlet>
    <div class="toast-container position-fixed top-0 end-0 p-3">
      <div id="toast" class="toast" role="alert">
        <div class="toast-header">
          <strong class="me-auto">{{ _toast.toastTitle }}</strong>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
        <div class="toast-body">{{ _toast.toastMessage }}</div>
      </div>
    </div>

    <div class="modal fade" id="modalPassword">
      <div class="modal-dialog modal-dialog-centered">
        <ng-template [ngIf]="_modalPassword.modal">
          <app-modal-change-password
            class="w-100"
            [title]="_modalPassword.title"
            [username]="_modalPassword.username"
            [type]="_modalPassword.type"
            [forceData]="_modalPassword.forceData"
            (closeModal)="_modalPassword.close()"
          ></app-modal-change-password>
        </ng-template>
      </div>
    </div>
  </router-outlet>
</div>
