<app-tile
  [title]="titleTile"
  [buttonText]="buttonText"
  (buttonClick)="goToApprovals()"
>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Client</th>
          <th>Name</th>
          <th>Account Title</th>
          <th>Items</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of dataItems">
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.accountTitle }}</td>
            <td class="text-end">({{ item.items }})</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</app-tile>
