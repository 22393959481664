import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  ClientDetailsComponent,
  ClientTransactionsComponent,
  ClientsListComponent,
  ControlCenterComponent,
  MainContainerComponent,
  SysopComponent,
  PasswordManagementComponent,
} from "./containers";
import { ControlRoutes } from "./routes";
import { NotfoundComponent } from "../shared/components";
import { authGuard } from "../login/services/auth.guard";

const routes: Routes = [
  {
    path: "",
    component: MainContainerComponent,
    canActivateChild: [authGuard],
    children: [
      {
        path: "",
        component: ControlCenterComponent,
      },
      {
        path: ControlRoutes.SysOperator,
        component: SysopComponent,
      },
      {
        path: ControlRoutes.PasswordManagement,
        component: PasswordManagementComponent,
      },
      {
        path: ControlRoutes.Clients,
        redirectTo: "", //temporally meanwhile we have this component
        pathMatch: "full",
        //component: ClientsListComponent,
      },
      {
        path: `${ControlRoutes.Clients}/:clientId`,
        component: ClientDetailsComponent,
      },
      {
        path: `${ControlRoutes.Clients}/:clientId/${ControlRoutes.AccountDetails}/:accountId`,
        component: ClientTransactionsComponent,
      },
      { path: "**", component: NotfoundComponent },
    ],
  },

  { path: "**", component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
