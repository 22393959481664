import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class HttpBaseService {
  protected readonly apiUrl: string = environment.zisApiBaseUrl;

  constructor(
    protected http: HttpClient,
    version: "v2" | null = null //if there is a new version add here  "v2" | "v3" | null = null
  ) {
    if (version) {
      this.apiUrl += `${version}/`;
    }
  }
}
