import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  sysOpModal: bootstrap.Modal | undefined;

  constructor() {}

  openModal(): void {
    const button = document.getElementById("showModalButton");
    button?.click();
  }
}
