export interface Logger {
  Application: string;
  Version: string;
  Environment: string;
  UserName: string;
}

export enum LogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
}

export interface LogEntry {
  entryDate: Date;
  level: LogLevel;
  message: string;
  logData: string[];
}
