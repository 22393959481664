import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { Client } from "src/app/pages/models";
import { BankAccountSubCodes } from "src/app/pages/models/bank-account-dto";
import { ControlRoutes } from "src/app/pages/routes";
import { ClientSearchService } from "src/app/pages/services";

@Component({
  selector: "app-header-title",
  templateUrl: "./header-title.component.html",
  styleUrls: ["./header-title.component.scss"],
})
export class HeaderTitleComponent implements OnInit, OnDestroy {
  @Input()
  title = "";
  @Input()
  hasBreadcrumbs = false;

  items: any[] = [];

  clientId: string | undefined;
  accountId: string | undefined;

  destroyComponent$ = new Subject<void>();

  constructor(
    private _clientSearch: ClientSearchService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._route.paramMap.pipe(takeUntil(this.destroyComponent$)).subscribe({
      next: (values) => {
        if (values.has("clientId")) {
          this.clientId = values.get("clientId") ?? undefined;
          this.accountId = values.get("accountId") ?? undefined;

          this.setBreadCrumbItems();
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.destroyComponent$.next();
    this.destroyComponent$.complete();
  }

  private setBreadCrumbItems(): void {
    this.title = "";
    this.items = [{ label: "Clients", route: "" }]; //reset breadcrumbs

    this._clientSearch.getClient(this.clientId!, this.accountId).subscribe({
      next: (client: Client | BankAccountSubCodes) => {
        if (!this.accountId) {
          client = client as Client;
          this.title = `${client.client} ${client.client_name}`;

          this.items.push({
            label: client.client_name,
            route: `${ControlRoutes.Clients}/${client.client}`,
          });
        } else {
          client = client as BankAccountSubCodes;

          this.title = `${client.CLIENT} ${client.CLIENT_NAME}`;

          this.items.push(
            {
              label: client.CLIENT_NAME,
              route: `${ControlRoutes.Clients}/${client.CLIENT}`,
            },
            {
              label: client.ACCOUNT_TITLE,
              route: `${ControlRoutes.Clients}/${client.CLIENT}/${ControlRoutes.AccountDetails}/${client.CODE}`,
            }
          );
        }
      },
    });
  }
}
