import { Component } from "@angular/core";

@Component({
  selector: "app-main-container",
  templateUrl: "./main-container.component.html",
  styleUrls: ["./main-container.component.scss"],
})
export class MainContainerComponent {
  todayDate: Date = new Date();
}
