import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Chart } from "chart.js/auto";
import {
  CHART_COLORS,
  currencyLabel,
  formatter,
} from "src/app/shared/utils/common-functions";
import { BankAccountSubCodes } from "../../models/bank-account-dto";
import { ControlRoutes } from "../../routes";
import { BankingService } from "../../services";

@Component({
  selector: "app-tile-bank-accounts",
  templateUrl: "./tile-bank-accounts.component.html",
  styleUrls: ["./tile-bank-accounts.component.scss"],
})
export class TileBankAccountsComponent implements OnChanges {
  @Input() clientId!: string;
  dataItems: BankAccountSubCodes[] = [];
  isLoading: boolean = true;
  chart: any = undefined;

  constructor(private bankingHTTP: BankingService, private _router: Router) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.isLoading = true;

    this.bankingHTTP.getBankAccounts(this.clientId).subscribe({
      next: (items: BankAccountSubCodes[]) => {
        this.dataItems = items;
      },
      complete: () => {
        this.isLoading = false;
        this.createChart();
      },
    });
  }

  createChart(): void {
    let labels: string[] = [];
    let values: number[] = [];
    let balance: number = 0;

    if (!!this.chart) {
      this.chart.destroy();
    }

    this.dataItems.forEach((item) => {
      labels.push(item.ACCOUNT_TITLE);
      values.push(item.ENDING_BALANCE);
      balance += item.ENDING_BALANCE;
    });

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Balance",
          data: values,
          borderColor: "#1E3860",
          backgroundColor: Object.values(CHART_COLORS),
        },
      ],
    };

    const balanceLabel = {
      id: "balanceLabel",
      beforeDraw(chart: any) {
        var width = chart.width,
          height = chart.height;

        var fontSize = (height / 220).toFixed(2);
        chart.ctx.font = fontSize + "em Verdana";
        chart.ctx.textBaseline = "middle";

        var text = formatter.format(balance),
          textX = Math.round((width - chart.ctx.measureText(text).width) / 2),
          textY = height / 2;

        chart.ctx.fillText(text, textX, textY);
      },
    };

    const accountsLabel = {
      id: "accountsLabel",
      beforeDraw(chart: any) {
        var width = chart.width,
          height = chart.height;

        var fontSize = (height / 270).toFixed(2);
        chart.ctx.font = fontSize + "em Verdana";
        chart.ctx.textBaseline = "middle";

        var text = "All Accounts",
          textX = Math.round((width - chart.ctx.measureText(text).width) / 2),
          textY = height / 1.7;

        chart.ctx.fillText(text, textX, textY);
      },
    };

    //NOTE: 'bankAccountsChart' is the id of the canvas where we want to render the chart
    this.chart = new Chart("bankAccountsChart", {
      type: "doughnut",
      data: data,
      plugins: [balanceLabel, accountsLabel],
      options: {
        cutout: 90,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: currencyLabel,
            },
          },
        },
      },
    });
  }

  onRowClick(item: BankAccountSubCodes): void {
    this._router.navigate([
      ControlRoutes.Clients,
      this.clientId,
      ControlRoutes.AccountDetails,
      item.CODE,
    ]);
  }
}
