import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RecentClientTileDTO } from "../../models/recent-client-tile-dto";
import { ControlRoutes } from "../../routes";
import { ClientSearchService } from "../../services";

@Component({
  selector: "app-tile-recent-clients",
  templateUrl: "./tile-recent-clients.component.html",
  styleUrls: ["./tile-recent-clients.component.scss"],
})
export class TileRecentClientsComponent implements OnInit {
  dataItems: RecentClientTileDTO[] = [];
  viewAllState: boolean = false;
  buttonText: string = this.setButtonText();
  isLoading: boolean = true;
  buttonState: boolean = true;

  constructor(
    private _clientSearch: ClientSearchService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      const clients = this.getData();
      this.dataItems = clients.slice(0, 5);
      this.buttonState = clients.length <= 5;
    }, 1000);
  }

  toggleViewList(): void {
    this.viewAllState = !this.viewAllState;
    this.isLoading = true;

    setTimeout(() => {
      if (this.viewAllState) {
        this.dataItems = this.getData();
      } else {
        this.dataItems = this.getData().slice(0, 5);
      }
    }, 500);

    this.buttonText = this.setButtonText();
  }

  setButtonText(): string {
    return this.viewAllState ? "View Less Clients" : "View All Clients";
  }

  getData(): RecentClientTileDTO[] {
    this.isLoading = false;
    return this._clientSearch.getRecentClients();
  }

  onRowClick(item: RecentClientTileDTO): void {
    this._router.navigate([ControlRoutes.Clients, item.id]);
  }
}
