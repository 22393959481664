import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import packageJson from "../../../../../package.json";
import { environment } from "src/environments/environment";
import { LogEntry, LogLevel, Logger } from "../../models/Logger";
import { HttpClient } from "@angular/common/http";
import { HttpBaseService } from "src/app/shared/services";
@Injectable({
  providedIn: "root",
})
export class LoggerService extends HttpBaseService {
  logger: Logger = {
    Application: "Control Center",
    Environment: environment.EnvironmentName,
    Version: packageJson.version,
    UserName: this.cookieService.get("userName"),
  };

  constructor(private cookieService: CookieService, private _http: HttpClient) {
    super(_http, "v2");
  }

  GetFullDescription() {
    return `${this.logger.Application} ${this.logger.Environment} ${this.logger.Version} ${this.logger.UserName}`;
  }

  LogTrace(message: string, data: any): void {
    const test: LogEntry = {
      entryDate: new Date(),
      level: LogLevel.Trace,
      message: message,
      logData: this._getData(data),
    };
    this._saveLog(test);
  }

  LogDebug(message: string, data: any): void {
    const test: LogEntry = {
      entryDate: new Date(),
      level: LogLevel.Debug,
      message: message,
      logData: this._getData(data),
    };
    this._saveLog(test);
  }

  LogInformation(message: string, data: any): void {
    const test: LogEntry = {
      entryDate: new Date(),
      level: LogLevel.Information,
      message: message,
      logData: this._getData(data),
    };
    this._saveLog(test);
  }

  LogWarning(message: string, data: any): void {
    const test: LogEntry = {
      entryDate: new Date(),
      level: LogLevel.Warning,
      message: message,
      logData: this._getData(data),
    };
    this._saveLog(test);
  }

  LogError(message: string, data: any): void {
    const test: LogEntry = {
      entryDate: new Date(),
      level: LogLevel.Error,
      message: message,
      logData: this._getData(data),
    };
    this._saveLog(test);
  }

  LogCritical(message: string, data: any): void {
    const test: LogEntry = {
      entryDate: new Date(),
      level: LogLevel.Critical,
      message: message,
      logData: this._getData(data),
    };
    this._saveLog(test);
  }

  private _getData(data: any): string[] {
    data.headers.lazyUpdate = data.headers.lazyUpdate?.filter(
      (item: any) =>
        item.name !== "Authorization" && item.name !== "X-ZIS-SessionKey"
    );

    return [this.logger, data];
  }

  private _saveLog(data: LogEntry): void {
    this._http.post(`${this.apiUrl}Log/control-center`, data).subscribe();
  }
}
