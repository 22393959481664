<div class="container-fluid h-100">
  <div class="row p-2 gradient-line"></div>
  <div class="row">
    <img class="p-3 zenith-logo m-auto" src="/assets/zenith-logo.png" />
  </div>
  <div
    class="login-container row justify-content-md-center align-items-md-center mt-5 mt-md-0"
  >
    <div class="col-12 col-md-6 col-lg-4 text-center">
      <form class="mb-5 fade show" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="mb-4">
          <h1>Welcome Back</h1>
          <p class="text-muted">Sign into your account</p>
        </div>

        <div
          class="alert alert-warning fade"
          [ngClass]="errorMessage && !submitting ? 'show' : 'p-0 m-0'"
        >
          <ng-template [ngIf]="errorMessage && !submitting">
            <i class="fa-solid fa-triangle-exclamation pe-2"></i>
            {{ errorMessage }}
          </ng-template>
        </div>
        <div
          class="alert alert-info fade"
          [ngClass]="timeoutMessage && !submitting ? 'show' : 'p-0 m-0'"
        >
          <ng-template [ngIf]="timeoutMessage && !submitting">
            <i class="fa-solid fa-circle-info pe-2"></i>
            {{ timeoutMessage }}
          </ng-template>
        </div>

        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="userName"
            placeholder="Username"
            required
            autofocus
            formControlName="userName"
          />
          <label for="userName">Username</label>
        </div>

        <div class="input-group mb-3">
          <div class="form-floating">
            <input
              [type]="passwordType"
              class="form-control"
              id="password"
              placeholder="Password"
              required
              formControlName="password"
            />
            <label for="password">Password</label>
          </div>
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="switchPasswordType()"
          >
            <i
              class="bi"
              [ngClass]="passwordType == 'password' ? 'bi-eye' : 'bi-eye-slash'"
            ></i>
          </button>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 col-sm-8 row">
            <button
              class="btn btn-primary"
              type="submit"
              [disabled]="form.invalid || submitting"
            >
              <ng-template [ngIf]="submitting">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </ng-template>
              Sign In
            </button>
          </div>
        </div>
      </form>

      <p>Powered by <img class="p-0" src="/assets/axos-logo.png" /></p>
    </div>
  </div>
  <p class="custom-footer text-center text-muted fixed-bottom mb-1 px-2">
    &copy; {{ todayDate | date : "yyyy" }} Zenith Information Systems, Inc. All
    Rights Reserved.
  </p>
</div>

<div class="modal fade" id="methodModal">
  <div class="modal-dialog modal-dialog-centered">
    <ng-template [ngIf]="methodModal">
      <app-modal-send-otp
        class="w-100"
        [userInfo]="userData"
        (closeModal)="closeMethodModal($event)"
      ></app-modal-send-otp>
    </ng-template>
  </div>
</div>

<div class="modal fade" id="otpModal">
  <div class="modal-dialog modal-dialog-centered">
    <ng-template [ngIf]="otpModal">
      <app-modal-verify-otp
        class="w-100"
        [submitting]="otpSubmitting"
        (closeModal)="closeOtpModal($event)"
        (backModal)="backMethodModal()"
      ></app-modal-verify-otp>
    </ng-template>
  </div>
</div>
