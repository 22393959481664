import { Component, OnInit } from "@angular/core";
import { RealEstateHoldingsTileDTO } from "../../models/real-estate-holdings-tile-dto";
import { MockedDataService } from "../../services/mocked-data.service";

@Component({
  selector: "app-tile-real-estate-holdings",
  templateUrl: "./tile-real-estate-holdings.component.html",
  styleUrls: ["./tile-real-estate-holdings.component.scss"],
})
export class TileRealEstateHoldingsComponent implements OnInit {
  dataItems: RealEstateHoldingsTileDTO[] = [];

  balance: number = 0;

  constructor(private _dataMock: MockedDataService) {}

  ngOnInit(): void {
    this.dataItems = this._dataMock.getMockedData<RealEstateHoldingsTileDTO[]>(
      "dataRealEstateHoldingsTile"
    );

    this.balance = this.dataItems.reduce((prev, current) => {
      return prev + Number(current.value);
    }, 0);
  }
}
