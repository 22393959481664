<app-tile
  title="Alerts (2)"
  [hasDivider]="false"
  [isLoading]="false"
  buttonText="View More"
>
  <ng-container *ngFor="let item of alerts">
    <app-alert-description-content
      [item]="item"
    ></app-alert-description-content>
  </ng-container>
</app-tile>
