export interface RealEstateHoldingsTileDTO {
  id: string;
  name: string;
  address: string;
  value: number;
  type: RealEstateType;
}

export enum RealEstateType {
  house = "house",
  department = "building",
  beach = "tree",
}
