import { Component, Input } from "@angular/core";

@Component({
  selector: "app-placeholder-tile",
  templateUrl: "./placeholder-tile.component.html",
  styleUrls: ["./placeholder-tile.component.scss"],
})
export class PlaceholderTileComponent {
  @Input() isMock: boolean = false;
}
