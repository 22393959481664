import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { MasonryService } from "src/app/shared/services/masonry.service";

@Component({
  selector: "app-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.scss"],
})
export class SidebarMenuComponent implements OnInit {
  menuStatus = true;
  currentState: string = "";

  constructor(private router: Router, private _masonry: MasonryService) {}

  ngOnInit(): void {
    this.updateCurrentState(this.router.url);

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: any) => {
        this.updateCurrentState(event.url);
      });
  }

  updateCurrentState(url: string): void {
    switch (true) {
      case url === "/":
        this.currentState = "controlCenter";
        break;

      case url.startsWith("/clients"):
        this.currentState = "clients";
        break;

      default:
        this.currentState = "";
        break;
    }
  }

  toggle(): void {
    this.menuStatus = !this.menuStatus;
    this._masonry.updateLayout();
  }

  onClick(url: string): void {
    this.router.navigate([url]);
  }
}
