<div class="card">
  <div class="card-body py-4 px-2">
    <div class="row mx-0">
      <div class="col-12 col-sm-7">
        <p class="fw-light m-0 fs-075">{{ detailData?.MASTER_TITLE }}</p>
        <span class="fw-semibold">{{ detailData?.ACCOUNT_TITLE }}</span>
      </div>
      <div class="col-12 col-sm-5 text-end">
        <span class="me-5">Balance:</span>
        <span class="text-nowrap">{{
          detailData?.ENDING_BALANCE | currency
        }}</span>
      </div>
    </div>
  </div>
</div>
