<app-tile title="Bank Accounts" [isLoading]="isLoading">
  <div class="chart-container mx-auto mb-3">
    <canvas id="bankAccountsChart">{{ chart }}</canvas>
  </div>
  <div class="row-container">
    <ng-container *ngFor="let item of dataItems">
      <app-row-data (click)="onRowClick(item)" role="button" class="row-hover">
        <!-- <div class="col-2">
          <i class="fs-3 bi bi-{{ item.type }}"></i>
        </div> -->
        <div class="col-8">
          <p class="fw-light m-0 fs-075">{{ item.MASTER_TITLE }}</p>
          <span class="fw-semibold">{{ item.ACCOUNT_TITLE }}</span>
        </div>
        <div class="col-4 text-end">
          <span>
            {{ item.ENDING_BALANCE | currency }}
          </span>
        </div>
      </app-row-data>
    </ng-container>
  </div>
</app-tile>
