import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { map, Observable, Observer, timer } from "rxjs";
import { ControlRoutes } from "src/app/pages/routes";
import { HttpBaseService, ModalService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { AuthCred } from "../models/auth-cred";
import { AuthResponse, UserInfoData } from "../models/auth-response";
import { LocalStorageService } from "src/app/pages/services";
import { DeviceInformationService } from ".";
import { OTPGet } from "../models/otp-get";
import { OTPSend } from "../models/otp-send";
import { OTPValidationResponse } from "../models/otp-validate";
import { RefreshTokenResponse } from "../models/refresh-token-response";
import { ChangeZenithPasswordRequest } from "../models/change-zenith-password-request";
import { ChangeAxosPasswordRequest } from "../models/change-axos-password-request";
import { ChangePasswordForm } from "../models/change-password-form";
import { ModalPasswordService } from "src/app/_core/services";

@Injectable({
  providedIn: "root",
})
export class AuthService extends HttpBaseService {
  private _loginTimerSSOMode: boolean = true;
  private _loginTimoutMessage: string = "";
  private _loginTimer = timer(0, 1000);
  private _userName: string = "";
  private _heartbeatTimeoutMsec = 60 * 15 * 1000;
  authResponse = signal<AuthResponse | null>(null);

  private _pingTimer = timer(0, 1000 * 60); // 1 minute

  constructor(
    private _http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private modalService: ModalService,
    private storage: LocalStorageService,
    private _deviceInformationService: DeviceInformationService,
    private _modalPassword: ModalPasswordService
  ) {
    //Setup SSO and login inactivity monitors
    super(_http, "v2");

    this._loginTimer.subscribe(() => {
      if (this._loginTimerSSOMode) {
        if (this.isLoggedIn) {
          this.loginSSO();
        }
      } else {
        if (this.isLoggedIn) {
          this.setCookieHeartbeat();

          let timeoutTime = Date.now() - this._heartbeatTimeoutMsec;
          let lastPing = Number(this.cookieService.get("ping"));

          this.refreshToken();

          if (lastPing <= timeoutTime) {
            this.logout();
          }
        } else {
          this._loginTimoutMessage =
            "For your security you have been logged out";
          this.logoutSSO();
        }
      }
    });

    this._pingTimer.subscribe({
      next: () => {
        if (cookieService.get("token")) {
          this.authPing();
        }
      },
    });
  }

  public get isLoggedIn(): boolean {
    if (this.cookieService.check("token")) {
      let heartbeatTimeoutTime = Date.now() - this._heartbeatTimeoutMsec;
      let lastHeartbeat = Number(this.cookieService.get("heartbeat"));

      if (lastHeartbeat <= heartbeatTimeoutTime) {
        this._loginTimoutMessage =
          "For your security you have been logged out due to inactivity";
        this.logout();
        return false;
      }

      let timeoutTime = Date.now() - this._heartbeatTimeoutMsec;
      let lastPing = Number(this.cookieService.get("ping"));
      if (lastPing <= timeoutTime) {
        this._loginTimoutMessage =
          "For your security you have been logged out due to inactivity";
        this.logout();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public get userName(): string {
    return this._userName;
  }

  public get loginTimoutMessage(): string {
    return this._loginTimoutMessage;
  }

  login(ac: AuthCred): Observable<UserInfoData> {
    ac.device = this._deviceInformationService.getDeviceInformation();
    ac.device.deviceId = this.storage.getItem("deviceId") ?? "";
    return this.http.post<AuthResponse>(this.apiUrl + "auth/token", ac).pipe(
      map((data: AuthResponse) => {
        this._loginTimerSSOMode = false;
        this._loginTimoutMessage = "";

        this.setAuthData(data);

        return {
          userId: data.userInfo.userInfo.userId,
          userName: data.userInfo.userInfo.userName,
          email: data.userInfo.userInfo.email,
          phoneNumber: data.userInfo.userInfo.phoneNumber,
        };
      })
    );
  }

  loginSSO() {
    this._loginTimerSSOMode = false;
    this._loginTimoutMessage = "";
    this._userName = this.cookieService.get("userName");
    this.setCookiePing();
    this.setCookieHeartbeat();

    if (this.router.routerState.snapshot.url.endsWith(ControlRoutes.Login)) {
      this.router.navigateByUrl("/");
    }
  }

  setAuthData(data: AuthResponse, completedProcess: boolean = false): void {
    {
      this._userName = data.userName;
      this.authResponse.set(data);

      this._deviceInformationService.updateDeviceInformation(
        data.userInfo.deviceInfo
      );

      this.setCookieHeartbeat();

      if (completedProcess) {
        this.setAuthCookie(
          data.token,
          data.userName,
          data.zenithUserName,
          data.userInfo.userInfo.userId,
          data.sessionId,
          data.sessionKey,
          data.hash ?? ""
        );
        if (data.sysop) {
          this.router.navigate([ControlRoutes.SysOperator]);
        } else {
          if (data?.userInfo?.deviceInfo.advice === 0) {
            let lastPage = this.storage.getItem("lastPage") ?? "/";
            this.router.navigateByUrl(lastPage);
          }
        }
      }
    }
  }

  getOTP(method: number): Observable<boolean> {
    const request = {
      userName: this.authResponse()?.userInfo.userInfo.userName,
      deliveryMethod: method,
    };

    return this.http.post<boolean>(
      this.apiUrl + "auth/GetOtp",
      request as OTPGet
    );
  }

  verifyOTP(data: OTPSend | undefined): Observable<OTPValidationResponse> {
    const device = this._deviceInformationService.getDeviceInformation();
    const request = {
      ...data,
      userId: this.authResponse()?.userInfo.userInfo.userId,
      userName: this.authResponse()?.userInfo.userInfo.userName,
      device: {
        deviceId: device.deviceId,
        signature: device.deviceSignature,
      },
    };

    return this.http
      .post<OTPValidationResponse>(this.apiUrl + "auth/ValidateOtp", request)
      .pipe(
        map((response: OTPValidationResponse) => {
          if (response.success) {
            response.authInfo.userInfo = this.authResponse()!.userInfo;
            response.authInfo.userInfo.deviceInfo.advice = 0;

            if (data?.rememberDevice) {
              this.storage.setItem("deviceId", device.deviceId);
            }
          }

          return response;
        })
      );
  }

  authPing(): void {
    this.http.put(this.apiUrl + "auth/ping", "").subscribe({
      //error means that the session is over
      error: (error) => {
        console.error(error);
        this.logoutSSO();
      },
    });
  }

  refreshToken(): void {
    let accum = this.storage.getItem("timeElapsed") ?? "0";
    let timeElapsed = Number(accum);
    timeElapsed++;

    this.storage.setItem("timeElapsed", timeElapsed.toString());

    if (
      this.cookieService.get("token") &&
      timeElapsed === Number(this.storage.getItem("interval") ?? "0")
    ) {
      this.storage.setItem("timeElapsed", "0");

      this.refresh();
    }
  }

  refresh() {
    this.http
      .put<RefreshTokenResponse>(this.apiUrl + "auth/refresh", {})
      .subscribe((response: RefreshTokenResponse) => {
        let seconds = response?.expiresIn * 0.75 ?? 2700;
        this.setCookie("token", response?.accessToken);
        this.storage.setItem("interval", seconds.toString());
      });
  }

  logout() {
    this.http.delete(this.apiUrl + "auth/logout").subscribe();

    //close the modal if is showing
    this.modalService.sysOpModal?.hide();
    this._modalPassword.close();

    this.logoutSSO();
  }

  logoutSSO() {
    this.cookieService.deleteAll("/", this.setHostDomain(), true, "Lax");

    this.router.navigateByUrl(ControlRoutes.Login);

    this._userName = "";
    this._loginTimerSSOMode = true;
    this.storage.deleteItem("recentClients");
    this.storage.setItem("timeElapsed", "0");
  }

  changeZenithPassword(
    data: ChangePasswordForm,
    forceData?: AuthResponse
  ): Observable<AuthResponse | undefined> {
    const dataRequest: ChangeZenithPasswordRequest = {
      password: data.currentPassword,
      newpassword: data.newPassword,
      username: data.zenithUsername ?? "",
    };

    if (forceData!!) {
      sessionStorage.setItem("token", forceData.token);
      sessionStorage.setItem("sessionKey", forceData.sessionKey);
      sessionStorage.setItem("sessionId", forceData.sessionId);
      sessionStorage.setItem("hash", forceData.hash!);
    }

    return this.http
      .post<AuthResponse>(
        this.apiUrl + "auth/changePassword/zenith",
        dataRequest
      )
      .pipe(
        map((data?: AuthResponse) => {
          sessionStorage.clear();

          if (data!!) {
            data.userInfo = this.authResponse()!.userInfo;
            data.userInfo.deviceInfo.advice = 0;
            this.setAuthData(data, !data.forceZenithPasswordChange);
          }

          return data;
        })
      );
  }

  changeAxosPassword(
    data: ChangePasswordForm,
    forceData?: AuthResponse
  ): Observable<void> {
    const dataRequest: ChangeAxosPasswordRequest = {
      userId:
        forceData?.userInfo.userInfo.userId.toString() ??
        this.cookieService.get("userId"),
      username: forceData?.userName ?? this.cookieService.get("userName"),
      password: data.newPassword,
    };

    if (forceData!!) {
      sessionStorage.setItem("token", forceData.token);
      sessionStorage.setItem("sessionKey", forceData.sessionKey);
      sessionStorage.setItem("sessionId", forceData.sessionId);
      sessionStorage.setItem("hash", forceData.hash!);
    }

    return this.http
      .put(this.apiUrl + "auth/changePassword/identity", dataRequest)
      .pipe(
        map(() => {
          sessionStorage.clear();
          return;
        })
      );
  }

  setHostDomain(): string {
    return window.location.hostname === "localhost"
      ? window.location.hostname
      : environment.cookieDomain;
  }

  setAuthCookie(
    token: string,
    userName: string,
    zenithUserName: string,
    userId: number,
    sessionId: string,
    sessionKey: string,
    hash: string
  ) {
    this.setCookie("ping", Date.now().toString());

    this.setCookie("heartbeat", Date.now().toString());

    if (
      !!token &&
      !!userName &&
      !!sessionId &&
      !!sessionKey &&
      !!zenithUserName &&
      !!userId
    ) {
      this.setCookie("token", token);

      this.setCookie("userName", userName);

      this.setCookie("zenithUserName", zenithUserName);

      this.setCookie("userId", userId.toString());

      this.setCookie("sessionId", sessionId);

      this.setCookie("sessionKey", sessionKey);

      this.setCookie("hash", hash);
    }
  }

  setCookieHeartbeat() {
    this.setCookie("heartbeat", Date.now().toString());
  }

  setCookiePing() {
    this.setCookie("ping", Date.now().toString());
  }

  private setCookie(name: string, value: string): void {
    this.cookieService.set(
      name,
      value,
      undefined,
      "/",
      this.setHostDomain(),
      true,
      "Lax"
    );
  }

  async validatePasswordsChanges(authResponse: AuthResponse): Promise<void> {
    if (authResponse.forceAxosPasswordChange) {
      await new Promise<void>((resolve) => {
        this._modalPassword.show("axos", authResponse);

        this._modalPassword.isClosed$.subscribe({
          complete: () => {
            resolve();
          },
        });
      });
    }

    if (
      authResponse.forceZenithPasswordChange ||
      !authResponse.zenithUserName
    ) {
      await new Promise<void>((resolve) => {
        this._modalPassword.show("zenith", authResponse);

        this._modalPassword.isClosed$.subscribe({
          complete: () => {
            resolve();
          },
        });
      });
    }

    return;
  }
}
