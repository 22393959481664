import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Chart } from "chart.js/auto";
import { BankingService } from "../../services";
import { Code } from "../../models";
import {
  CHART_COLORS,
  currencyLabel,
} from "src/app/shared/utils/common-functions";
@Component({
  selector: "app-investment-tile",
  templateUrl: "./investment-tile.component.html",
  styleUrls: ["./investment-tile.component.scss"],
})
export class InvestmentTileComponent implements OnInit, OnChanges {
  @Input() clientId = "";
  dataSeed: Code[] = [];
  isLoading: boolean = true;

  chart: any;
  balance = 0;

  constructor(private _bankingService: BankingService) {}

  ngOnChanges(): void {
    this.isLoading = true;

    this._getData();
  }

  ngOnInit(): void {
    this._getData();
  }

  createChart(): void {
    if (this.chart) {
      this.chart.destroy();
    }

    const labels = this.dataSeed.map((x) => {
      return x.MASTER.toString();
    });
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Balance",
          data: this.dataSeed.map((x) => {
            return x.ENDING_BALANCE.toString();
          }),
          borderColor: "#1E3860",
          backgroundColor: Object.values(CHART_COLORS),
        },
      ],
    };

    //NOTE: 'MyChart' is the id of the canvas where we want to render the chart
    this.chart = new Chart("MyChart", {
      type: "bar",
      data: data,

      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: currencyLabel,
            },
          },
        },
      },
    });
  }

  private _getData(): void {
    this._bankingService.getBankInvestmentData(this.clientId).subscribe({
      next: (data) => {
        this.dataSeed = data;
      },
      complete: () => {
        this.isLoading = false;

        this._calculateTotal();
        this.createChart();
      },
    });
  }

  private _calculateTotal(): void {
    this.balance = this.dataSeed.reduce((prev, current) => {
      return prev + Number(current.ENDING_BALANCE);
    }, 0);
  }
}
