import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  setItem(itemName: string, itemValue: any): void {
    localStorage.setItem(itemName, itemValue);
  }

  getItem(itemName: string): string | null {
    return localStorage.getItem(itemName);
  }

  clear(): void {
    localStorage.clear();
  }

  deleteItem(itemName: string): void {
    localStorage.removeItem(itemName);
  }
}
