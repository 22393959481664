import { Component } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs";
import { ClientSearchService, LocalStorageService } from "./pages/services";
import { ModalPasswordService, ToastService } from "./_core/services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "ZenithControlCenter";

  constructor(
    private router: Router,
    private storage: LocalStorageService,
    public _toast: ToastService,
    public _modalPassword: ModalPasswordService,
    private _clientSearch: ClientSearchService
  ) {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationStart => event instanceof NavigationStart
        )
      )
      .subscribe((event: NavigationStart) => {
        if (event.url !== "/login") {
          this.storage.setItem("lastPage", event.url);

          let urlSegments = event.url.split("/");

          if (event.url.startsWith("/clients") && urlSegments.length === 3) {
            this._clientSearch.setRecentClients(urlSegments.pop() ?? "");
          }
        }
      });
  }
}
