import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tile-alerts",
  templateUrl: "./tile-alerts.component.html",
  styleUrls: ["./tile-alerts.component.scss"],
})
export class TileAlertsComponent implements OnInit {
  title: string = this.setTitleText();
  isLoading: boolean = true;

  ngOnInit(): void {
    this.title = this.setTitleText(27);
  }

  setTitleText(newValue: number = 0): string {
    return `Alerts (${newValue})`;
  }
}
