<div class="modal-content">
  <!-- Header -->
  <div class="modal-header">
    <h5 class="modal-title">{{ title }}</h5>
    <button
      type="button"
      class="btn-close"
      (click)="onCloseClick()"
      [disabled]="submitting"
      *ngIf="!forceData"
    ></button>
  </div>
  <!-- Content -->
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-floating mb-3" *ngIf="!username; else usernameLabel">
        <input
          class="form-control"
          placeholder="Zenith Username"
          type="text"
          formControlName="zenithUsername"
          id="zenithUsername"
          required
          autofocus
        />
        <label for="zenithUsername">Zenith Username</label>
      </div>

      <ng-template #usernameLabel>
        <h6 class="mb-3">Username: {{ username }}</h6>
      </ng-template>

      <div class="input-group mb-3">
        <div class="form-floating">
          <input
            [type]="currentPasswordType"
            class="form-control"
            id="currentPassword"
            placeholder="Current Password"
            required
            formControlName="currentPassword"
          />
          <label for="currentPassword">Current Password</label>
        </div>
        <button
          class="btn btn-outline-secondary"
          type="button"
          (click)="
            currentPasswordType = switchPasswordType(currentPasswordType)
          "
        >
          <i
            class="bi"
            [ngClass]="
              currentPasswordType == 'password' ? 'bi-eye' : 'bi-eye-slash'
            "
          ></i>
        </button>
      </div>

      <hr />

      <div class="input-group mb-0">
        <div class="form-floating">
          <input
            [type]="newPasswordType"
            class="form-control"
            id="newPassword"
            placeholder="New Password"
            required
            formControlName="newPassword"
          />
          <label for="newPassword">New Password</label>
        </div>
        <button
          class="btn btn-outline-secondary"
          type="button"
          (click)="newPasswordType = switchPasswordType(newPasswordType)"
        >
          <i
            class="bi"
            [ngClass]="
              newPasswordType == 'password' ? 'bi-eye' : 'bi-eye-slash'
            "
          ></i>
        </button>
      </div>
      <div id="newPasswordHelp" class="form-text">
        <ul>
          <li
            [ngClass]="{'text-success':  newPassword!.dirty && !newPassword!.errors?.['required'] && !newPassword!.errors?.['numberValidator'], 'text-danger':  newPassword!.dirty && !!newPassword!.errors?.['numberValidator']}"
          >
            At least 1 number
          </li>
          <li
            [ngClass]="{'text-success':  newPassword!.dirty && !newPassword!.errors?.['required'] && !newPassword!.errors?.['upperValidator'], 'text-danger':  newPassword!.dirty && !!newPassword!.errors?.['upperValidator']}"
          >
            At least 1 uppercase letter
          </li>
          <li
            [ngClass]="{'text-success':  newPassword!.dirty && !newPassword!.errors?.['required'] && !newPassword!.errors?.['lowerValidator'], 'text-danger':  newPassword!.dirty && !!newPassword!.errors?.['lowerValidator']}"
          >
            At least 1 lowercase letter
          </li>
          <li
            [ngClass]="{'text-success':  newPassword!.dirty && !newPassword!.errors?.['required'] && !newPassword!.errors?.['specialValidator'], 'text-danger':  newPassword!.dirty && !!newPassword!.errors?.['specialValidator']}"
          >
            At least 1 special character
          </li>
          <li
            [ngClass]="{'text-success':  newPassword!.dirty && !newPassword!.errors?.['required'] && !newPassword!.errors?.['minlength'], 'text-danger':  newPassword!.dirty && !!newPassword!.errors?.['minlength']}"
          >
            Minimum 13 characters
          </li>
          <li
            [ngClass]="{'text-success':  newPassword!.dirty && !newPassword!.errors?.['required'] && !newPassword!.errors?.['maxlength'], 'text-danger':  newPassword!.dirty && !!newPassword!.errors?.['maxlength']}"
          >
            Maximum 32 characters
          </li>
          <li
            [ngClass]="{'text-success':  newPassword!.dirty && !newPassword!.errors?.['required'] && !newPassword!.errors?.['beginEndNumberValidator'], 'text-danger':  newPassword!.dirty && !!newPassword!.errors?.['beginEndNumberValidator']}"
          >
            Cannot begin or end with a number
          </li>
        </ul>
      </div>

      <div class="input-group mb-0">
        <div class="form-floating">
          <input
            [type]="confirmPasswordType"
            class="form-control"
            id="confirmPassword"
            placeholder="Confirm New Password"
            required
            formControlName="confirmPassword"
          />
          <label for="confirmPassword">Confirm New Password</label>
        </div>
        <button
          class="btn btn-outline-secondary"
          type="button"
          (click)="
            confirmPasswordType = switchPasswordType(confirmPasswordType)
          "
        >
          <i
            class="bi"
            [ngClass]="
              confirmPasswordType == 'password' ? 'bi-eye' : 'bi-eye-slash'
            "
          ></i>
        </button>
      </div>
      <div id="confirmPasswordHelp" class="form-text">
        <ul>
          <li
            *ngIf="newPassword!.dirty && !newPassword!.errors?.['required'] && confirmPassword!.dirty && !confirmPassword!.errors?.['required'] && !form.errors?.['matchValidator']"
            class="text-success"
          >
            Password matches
          </li>
          <li
            *ngIf="confirmPassword!.dirty && !confirmPassword!.errors?.['required'] && !!form.errors?.['matchValidator']"
            class="text-danger"
          >
            Password does not match
          </li>
        </ul>
      </div>
    </form>
  </div>
  <!-- Footer -->
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [disabled]="form.invalid || submitting"
    >
      <ng-template [ngIf]="submitting">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </ng-template>
      Change Password
    </button>
  </div>
</div>
