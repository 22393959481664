import { Component, Input, OnInit } from "@angular/core";
import { BankTransactionsPostingDetails } from "../../models";
import { BankingTransactionsService } from "../../services";
import { Router } from "@angular/router";
import { MasonryService } from "src/app/shared/services/masonry.service";

@Component({
  selector: "app-tile-transactions",
  templateUrl: "./tile-transactions.component.html",
  styleUrls: ["./tile-transactions.component.scss"],
})
export class TileTransactionsComponent implements OnInit {
  @Input() clientId!: string;
  transactions: BankTransactionsPostingDetails[] = [];
  accountId: string | undefined = "";
  isLoading = true;
  disabled = false;

  periods = [
    { id: 1, option: "Current Month", value: "Current_Month" },
    { id: 2, option: "Current Day", value: "Current_Day" },
    { id: 3, option: "Current Week", value: "Current_Week" },
    {
      id: 4,
      option: "Current Fiscal Quarter",
      value: "Current_Fiscal_Quarter",
    },
    {
      id: 5,
      option: "Current Fiscal Half Year",
      value: "Current_Fiscal_Halfyear",
    },
    { id: 6, option: "Current Fiscal Year", value: "Current_Fiscal_Year" },
    {
      id: 7,
      option: "Current Calendar Quarter",
      value: "Current_Calendar_Quarter",
    },
    {
      id: 8,
      option: "Current Calendar Half Year",
      value: "Current_Calendar_Halfyear",
    },
    { id: 9, option: "Current Calendar Year", value: "Current_Calendar_Year" },
  ];

  pages = [
    { id: 1, option: "10 per page", value: 10 },
    { id: 2, option: "15 per page", value: 15 },
    { id: 3, option: "20 per page", value: 20 },
    { id: 4, option: "All", value: 0 },
  ];
  currentPage: number = 1;
  selectedPeriodOption: string = "";
  selectedPageOption: string = "";
  pageSize: number = 10;

  constructor(
    private _transactionsService: BankingTransactionsService,
    private _router: Router,
    private _masonry: MasonryService
  ) {}

  ngOnInit() {
    this.accountId = this._router.url.split("/").pop();
    this.fetch(this.periods[0]);
  }

  fetch(periodOption: any): void {
    this._transactionsService
      .getTransactions(this.accountId!, periodOption.value, this.clientId)
      .subscribe({
        next: (response: any) => {
          const items = response["Posting Details"];
          this.transactions = items;
        },
        complete: () => {
          this.setTileState(periodOption.option);
        },
      });
  }

  setTileState(period: string): void {
    this.selectedPeriodOption = period;
    this.isLoading = false;
    this.currentPage = 1;
    if (this.transactions.length > 0) {
      this.disabled = false;
      this.pages[3].value = this.transactions.length;
      this.selectedPageOption =
        this.transactions.length > 10
          ? this.pages[0].option
          : this.pages[3].option;
      this.pageSize = 10;
    } else {
      this.disabled = true;
      this.pages[3].value = 0;
      this.selectedPageOption = "All";
    }
  }

  onChangePageOption(event: any): void {
    this.pageSize = event.value;
    this.currentPage = 1;
    this._masonry.updateLayout();
  }

  onChangePeriodOption(event: any): void {
    this.isLoading = true;
    this.selectedPeriodOption = "";
    this.selectedPageOption = "";
    this.fetch(event);
  }

  onPageChange(event: any): void {
    this.currentPage = event;
    this._masonry.updateLayout();
  }

  isDisabled(page: any): boolean {
    if (page.option === "All") {
      return false;
    }
    return page.value >= this.transactions.length;
  }
}
