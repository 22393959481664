export interface BankAccountDTO {
  "Banks & Credit Lines w/MICR": BankAccountSubCodes[];
}

export interface BankAccountSubCodes {
  CUSTOMER: string;
  CLIENT: string;
  FYE: Date;
  MASTER: string;
  SUB: string;
  CODE: string;
  MASTER_TITLE: string;
  ACCOUNT_TITLE: string;
  ENDING_BALANCE: number;
  CLIENT_NAME: string;
  FormattedCode: string;
  MicrAccountNumberClean: string;
  RoutingtNumberClean: string;
}
