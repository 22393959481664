<app-tile title="Real Estate Holdings" [hasDivider]="true">
  <app-sub-tile-balance
    subTitle="Total Market Value"
    [balance]="balance"
  ></app-sub-tile-balance>
  <div class="row-container">
    <ng-container *ngFor="let item of dataItems">
      <app-row-data>
        <div class="col-2">
          <i class="fs-3 bi bi-{{ item.type }}"></i>
        </div>
        <div class="col-6">
          <span class="fw-semibold">{{ item.id }} - {{ item.name }}</span>
          <p class="fw-light m-0 fs-075">{{ item.address }}</p>
        </div>
        <div class="col-4 text-end">
          <span class="">
            {{ item.value | currency }}
          </span>
        </div>
        <div></div>
      </app-row-data>
    </ng-container>
  </div>
</app-tile>
