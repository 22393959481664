import { Component } from "@angular/core";

@Component({
  selector: "app-tile-zenith-gold",
  templateUrl: "./tile-zenith-gold.component.html",
  styleUrls: ["./tile-zenith-gold.component.scss"],
})
export class TileZenithGoldComponent {
  title: string = "Learning Center";
  isLoading: boolean = true;
}
