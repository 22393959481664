<app-tile
  title="Overdraft Accounts ({{
    _bankingService.accountsSubCodesTotalSignal()
  }})"
  [isLoading]="_bankingService.accountsSubCodesLoadingSignal()"
>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Client</th>
          <th scope="col">Name</th>
          <th scope="col">Code</th>
          <th scope="col">Account</th>
          <th scope="col">Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of _bankingService.accountsSubCodesSignal()
              | paginate
                : {
                    id: 'pages',
                    itemsPerPage: pageSize,
                    currentPage: currentPage,
                    totalItems: _bankingService.accountsSubCodesSignal().length
                  }
          "
          (click)="onRowClick(item)"
          role="button"
        >
          <td>
            {{ item.CLIENT }}
          </td>
          <td>
            {{ item.CLIENT_NAME }}
          </td>
          <td>
            {{ item.FormattedCode }}
          </td>
          <td>
            {{ item.ACCOUNT_TITLE }}
          </td>
          <td class="text-end">{{ item.ENDING_BALANCE | currency }}</td>
        </tr>
      </tbody>
    </table>
    <!-- Pagination -->
    <div *ngIf="!disabled" class="table-footer">
      <pagination-controls
        id="pages"
        previousLabel=""
        nextLabel=""
        (pageChange)="onPageChange($event)"
      >
      </pagination-controls>
    </div>
  </div>
</app-tile>
