<!-- Verify OTP Code -->

<div class="modal-content">
  <!-- Header -->
  <div class="modal-header">
    <h5 class="modal-title">Verify Security Code</h5>
    <button
      type="button"
      class="btn-close"
      (click)="onCloseClick()"
      [disabled]="submitting"
    ></button>
  </div>
  <!-- Content -->
  <div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h6 class="mb-3">Enter the OTP code you received:</h6>

      <div class="input-group mb-3">
        <div class="form-floating">
          <input
            class="form-control"
            type="tel"
            placeholder="OTP Code"
            formControlName="otp"
            id="otp"
            required
            autofocus
            minlength="6"
            maxlength="6"
            pattern="^[0-9]*$"
          />
          <label for="otp">OTP Code</label>
        </div>
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="form.invalid || submitting"
        >
          <ng-template [ngIf]="submitting">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </ng-template>
          Verify
        </button>
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="rememberDevice"
          id="rememberDevice"
        />
        <label class="form-check-label" for="rememberDevice">
          Remember device
        </label>
      </div>
    </form>
  </div>
  <!-- Footer -->
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary me-auto"
      (click)="onResendClick()"
    >
      Resend OTP
    </button>
  </div>
</div>
