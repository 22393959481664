<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarTogglerDemo01"
      aria-controls="navbarTogglerDemo01"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mt-3" id="navbarTogglerDemo01">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a
            class="nav-link active"
            aria-current="page"
            style="color: #1e3860; font-weight: 600"
            href="/"
          >
            Control Center
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" style="font-weight: 600" role="button">
            Clients
          </a>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <a
            class="nav-link dropdown-item"
            [routerLink]="[ControlRoutes.PasswordManagement]"
            >Password Management</a
          >
        </li>
        <li><a class="nav-link disabled" role="button">Preferences</a></li>

        <li>
          <a class="nav-link" (click)="logout()" role="button"> Logout </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
