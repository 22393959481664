import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import * as bootstrap from "bootstrap";
import { ModalService } from "../../services";

@Component({
  selector: "app-control-center-modal",
  templateUrl: "./control-center-modal.component.html",
  styleUrls: ["./control-center-modal.component.scss"],
})
export class ControlCenterModalComponent {
  @Input() modalTitle?: string;
  @Output() buttonClose = new EventEmitter<void>();
  @Output() buttonSaveChanges = new EventEmitter<void>();

  constructor(private modalService: ModalService) {}

  //use if you have something like viewchild or you can use the service
  openModal(): void {
    this.modalService.openModal();
  }

  createModal(element: any) {
    this.modalService.sysOpModal = new bootstrap.Modal(element, {});
    this.modalService.sysOpModal?.show();
  }

  saveChanges(): void {
    this.buttonSaveChanges.emit();
  }

  close(): void {
    //Hide modal
    this.modalService.sysOpModal?.hide();
    this.buttonClose.emit();
  }
}
