<app-row-data>
  <div class="d-flex flex-column" style="width: 100%">
    <section class="d-flex justify-content-between">
      <div class="d-flex">
        <span class="alert-icon me-3">
          <i
            class="fa-sharp fa-solid fa-circle fa-2xs"
            *ngIf="item?.hasIcon"
          ></i>
        </span>
        <div class="d-flex flex-column">
          <p class="alert-text me-4">{{ item?.alertName }}</p>
          <p class="alert-description">{{ item?.alertDescription }}</p>
        </div>
      </div>
      <span class="">{{ item?.duration }}</span>
    </section>
  </div>
</app-row-data>
