import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  ClientDetailsComponent,
  ClientTransactionsComponent,
  ClientsListComponent,
  ControlCenterComponent,
  MainContainerComponent,
  SysopComponent,
  PasswordManagementComponent,
} from "./containers";

import {
  HMenuComponent,
  HeaderBarComponent,
  SidebarMenuComponent,
  InvestmentTileComponent,
  OverdrawnAccountTileComponent,
  TileRecentClientsComponent,
  TileRealEstateHoldingsComponent,
  TileApprovalsComponent,
  TileAccoutSummaryComponent,
  TileAlertsComponent,
  TileZenithGoldComponent,
  TileDocumentsComponent,
  TileAlertsTransactionsComponent,
  AlertDescriptionContentComponent,
  TileMessagesComponent,
  TileDebtComponent,
  TileBankAccountsComponent,
  TileBankAccountDetailComponent,
  TileTransactionsComponent,
} from "./components";

import {
  BankingService,
  MockedDataService,
  ClientSearchService,
} from "./services";

import { PagesRoutingModule } from "./pages-routing.module";
import { SharedModule } from "../shared/shared.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";

@NgModule({
  declarations: [
    MainContainerComponent,
    ControlCenterComponent,
    ClientsListComponent,
    ClientDetailsComponent,
    ClientTransactionsComponent,
    SidebarMenuComponent,
    HeaderBarComponent,
    HMenuComponent,
    TileRecentClientsComponent,
    InvestmentTileComponent,
    OverdrawnAccountTileComponent,
    TileRealEstateHoldingsComponent,
    TileApprovalsComponent,
    TileAccoutSummaryComponent,
    TileAlertsComponent,
    TileZenithGoldComponent,
    TileDocumentsComponent,
    TileAlertsTransactionsComponent,
    AlertDescriptionContentComponent,
    TileMessagesComponent,
    TileDebtComponent,
    TileBankAccountsComponent,
    TileBankAccountDetailComponent,
    TileTransactionsComponent,
    SysopComponent,
    PasswordManagementComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PagesRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
  ],
  providers: [MockedDataService, BankingService, ClientSearchService],
})
export class PagesModule {}
