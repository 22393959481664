import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "src/app/login/services/auth.service";
import { ClientSearchService, LocalStorageService } from "../../services";
import { Client } from "../../models";
import { Router } from "@angular/router";
import { ControlRoutes } from "../../routes";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "app-header-bar",
  templateUrl: "./header-bar.component.html",
  styleUrls: ["./header-bar.component.scss"],
})
export class HeaderBarComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent!: NgSelectComponent;
  readonly ControlRoutes = ControlRoutes;

  clients: Client[] = [];

  constructor(
    private authHTTP: AuthService,
    private _clientSearch: ClientSearchService,
    private _router: Router,
    private _storage: LocalStorageService
  ) {}

  ngOnInit() {
    this._clientSearch.getClients().subscribe({
      next: (response) => (this.clients = response),
      error: (err) => console.log(err),
    });
  }

  onChange(client: any): void {
    if (client?.client_name?.length > 0) {
      this._router.navigate([ControlRoutes.Clients, client.client]);
      this._clientSearch.selectedClient.set(client); //Update selected client (signal)
    }
  }

  reset() {
    this.ngSelectComponent.handleClearClick();
  }

  logout(): void {
    this._storage.setItem("lastPage", "/");
    this.authHTTP.logout();
  }
}
