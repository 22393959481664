<app-tile
  title="Recent Clients"
  [buttonText]="buttonText"
  (buttonClick)="toggleViewList()"
  [isLoading]="isLoading"
  [buttonState]="buttonState"
>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Client</th>
          <th>Name</th>
          <th>Last Posted Date</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of dataItems">
          <tr (click)="onRowClick(item)" role="button">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.postedDate | date : "MM/dd/yyyy" }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</app-tile>
