import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MasonryService } from "src/app/shared/services/masonry.service";

@Component({
  selector: "app-client-details",
  templateUrl: "./client-details.component.html",
  styleUrls: ["./client-details.component.scss"],
})
export class ClientDetailsComponent implements OnInit, AfterViewInit {
  title = "";
  breadcrumb: string[] = ["Clients", ">"];
  clientId = "";

  constructor(
    private _route: ActivatedRoute,
    private _masonry: MasonryService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((value) => {
      this.clientId = value["clientId"];
    });
  }

  ngAfterViewInit(): void {
    this._masonry.initMasonry();
  }
}
