import { Injectable, signal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { RecentClientTileDTO, Client } from "../models";
import { BankAccountSubCodes } from "../models/bank-account-dto";
import { buildHttpOptions } from "src/app/shared/utils/common-functions";
import { HttpBaseService } from "src/app/shared/services";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class ClientSearchService extends HttpBaseService {
  selectedClient = signal<Client | null>(null);
  clientList = signal<Client[]>([]);
  recentClients = signal<RecentClientTileDTO[]>([]);

  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService
  ) {
    super(_http, "v2");
  }

  getClients(): Observable<Client[]> {
    return this._http.get<Client[]>(this.apiUrl + "clients").pipe(
      map((response) => {
        this.clientList.set(response as Client[]);
        return response;
      })
    );
  }

  getClient(
    clientId: string,
    accountId?: string
  ): Observable<Client | BankAccountSubCodes> {
    let options = accountId
      ? buildHttpOptions({ params: { accountId: accountId } })
      : undefined;

    return this._http
      .get<Client | BankAccountSubCodes>(
        `${this.apiUrl}clients/${clientId}`,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getRecentClients(): RecentClientTileDTO[] {
    let recentClients: RecentClientTileDTO[] = JSON.parse(
      this._storage.getItem("recentClients") ?? "[]"
    );

    return recentClients;
  }

  setRecentClients(clientId: string): void {
    if (this.clientList().length === 0) {
      this.getClients().subscribe({
        next: () => {
          this.setStack(clientId);
        },
        error: (err) => console.log(err),
      });
      return;
    }
    this.setStack(clientId);
  }

  setStack(clientId: string) {
    if (!!clientId) {
      let selectedClient = this.findByClientId(clientId);

      // recent clients stack
      let recentClients = this.getRecentClients();

      const existingClientIndex = recentClients.findIndex(
        (client) => client.id === selectedClient.id
      );
      if (existingClientIndex >= 0) {
        recentClients.splice(existingClientIndex, 1);
      }

      recentClients.unshift(selectedClient); // add client on top

      if (recentClients.length > 10) {
        recentClients.pop(); // remove client at the end
      }
      this._storage.setItem("recentClients", JSON.stringify(recentClients));
    }
  }

  private findByClientId(clientId: string): RecentClientTileDTO {
    let client = this.clientList().find((client) => client.client === clientId);

    return this.mapClient(client) ?? {};
  }

  private mapClient(client: Client | undefined): RecentClientTileDTO {
    return {
      id: client!.client,
      name: client!.client_name,
      postedDate:
        new Date(client!.posted).getFullYear() === 2023 ? client!.posted : "",
    };
  }
}
