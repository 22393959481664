import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function numberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.value;

    if (newPassword) {
      const validator = /\d/.test(newPassword);

      return validator
        ? null
        : {
            numberValidator: {
              valid: validator,
            },
          };
    }

    return null;
  };
}

export function beginEndNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.value;

    if (newPassword) {
      const begin = /^\d/.test(newPassword);
      const end = /\d+$/.test(newPassword);

      return !begin && !end
        ? null
        : {
            beginEndNumberValidator: {
              valid: !begin && !end,
            },
          };
    }

    return null;
  };
}

export function upperCaseValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.value;

    if (newPassword) {
      const validator = /[A-Z]/.test(newPassword);
      return validator ? null : { upperValidator: { valid: validator } };
    }

    return null;
  };
}

export function lowerCaseValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.value;

    if (newPassword) {
      const validator = /[a-z]/.test(newPassword);
      return validator ? null : { lowerValidator: { valid: validator } };
    }

    return null;
  };
}

export function specialValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.value;

    if (newPassword) {
      const validator = /\W/.test(newPassword);
      return validator ? null : { specialValidator: { valid: validator } };
    }

    return null;
  };
}

export function matchValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.get("newPassword");
    const confirmPassword = control.get("confirmPassword");

    if (confirmPassword?.dirty && !!confirmPassword.value) {
      const validator = newPassword?.value === confirmPassword.value;
      return validator ? null : { matchValidator: { valid: validator } };
    }

    return null;
  };
}
