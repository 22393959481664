// Generated by https://quicktype.io

export interface OverDrawnAccountDto {
  "Accounts (Sub Codes)": AccountsSubCodes[];
}

export interface AccountsSubCodes {
  CUSTOMER: string;
  CLIENT: string;
  FYE: string;
  MASTER: string;
  SUB: string;
  CODE: string;
  MASTER_TITLE: string;
  ACCOUNT_TITLE: string;
  ENDING_BALANCE: number;
  CLIENT_NAME: string;
  FormattedCode: string;
}
