import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NotfoundComponent,
  TileComponent,
  SubTileBalanceComponent,
  RowDataComponent,
  PlaceholderTileComponent,
  ControlCenterModalComponent,
  ModalChangePasswordComponent,
} from "./components";
import { HeaderTitleComponent } from "./components/header-title/header-title.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    NotfoundComponent,
    TileComponent,
    SubTileBalanceComponent,
    RowDataComponent,
    PlaceholderTileComponent,
    HeaderTitleComponent,
    ControlCenterModalComponent,
    ModalChangePasswordComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    NotfoundComponent,
    TileComponent,
    SubTileBalanceComponent,
    RowDataComponent,
    HeaderTitleComponent,
    ControlCenterModalComponent,
    ModalChangePasswordComponent,
  ],
})
export class SharedModule {}
