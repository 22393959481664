<app-tile
  title="Investment Accounts"
  [hasDivider]="true"
  [isLoading]="isLoading"
>
  <app-sub-tile-balance
    subTitle="Total Investing"
    [balance]="balance"
  ></app-sub-tile-balance>
  <div class="chart-container mx-auto">
    <canvas id="MyChart">{{ chart }}</canvas>
  </div>
  <div>
    <ng-container *ngFor="let item of dataSeed">
      <app-row-data>
        <span
          class="text-truncate has-tooltip"
          data-bs-toggle="tooltip"
          title="{{ item.MASTER_TITLE }}"
        >
          {{ item.MASTER }} - {{ item.MASTER_TITLE }}
        </span>
        <span>
          {{ item.ENDING_BALANCE | currency }}
        </span>
      </app-row-data>
    </ng-container>
  </div>
</app-tile>
