import { Injectable } from "@angular/core";
import { DeviceInformation } from "../models/device-information.type";
declare let ca: any;
@Injectable({
  providedIn: "root",
})
export class DeviceInformationService {
  private caClient: any;
  private deviceInformation: DeviceInformation = {};

  constructor() {
    const client = new ca.rm.Client();
    client.setProperty("format", "json");
    client.setProperty("store", "localstorage");
    client.setProperty("didname", "rmClient");
    client.setProperty("noFlash", true);
    this.caClient = client;

    this.initializeDDNA();
  }

  /**
   * Intitializes Device DNA with the CA Client.
   */

  initializeDDNA(): void {
    this.caClient.processDNA();
    this.deviceInformation.deviceSignature = this.caClient
      .getDNA()
      .replace(/\s/g, "");

    this.deviceInformation.deviceId = this.caClient.getDID();
  }

  getDeviceInformation(): DeviceInformation {
    return this.deviceInformation;
  }

  updateDeviceInformation(deviceInfo: DeviceInformation): void {
    this.deviceInformation = deviceInfo;
  }

  /**
   * Sets the device ID for the CA Client.
   * @param did Device ID.
   */
  setDID(did: string): void {
    this.caClient.setDID(did);
  }
}
