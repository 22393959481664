<header class="border-bottom">
  <div class="d-grid align-items-center">
    <div class="d-flex gap-5 align-items-center profile-menu">
      <!-- Search Bar -->
      <div class="search-bar form-group col-12 col-lg-6 me-auto">
        <ng-select
          class="w-100"
          name="clientSelect"
          [multiple]="false"
          (change)="onChange($event)"
          (close)="reset()"
          style="font-family: Arial, FontAwesome"
          placeholder="&#xf002;&nbsp;&nbsp;Search Clients"
        >
          <ng-option *ngFor="let client of clients" [value]="client">
            {{ client.client }} {{ client.client_name }}
          </ng-option>
        </ng-select>
      </div>

      <!-- Profile Menu Container-->
      <div class="d-none d-lg-flex gap-3">
        <!-- Bell Icon -->
        <span class="pe-2">
          <i class="bi bi-bell"></i>
        </span>

        <!-- Settings Icon -->
        <span class="pe-2">
          <i class="bi bi-gear"></i>
        </span>

        <!-- Profile Icon -->
        <div class="flex-shrink-0 dropdown">
          <a
            class="d-block link-dark text-decoration-none dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            role="button"
          >
            <i class="fa-solid fa-user fa-lg"></i>
          </a>
          <ul class="dropdown-menu text-small shadow">
            <li>
              <a
                class="dropdown-item"
                [routerLink]="[ControlRoutes.PasswordManagement]"
                >Password Management</a
              >
            </li>
            <li><a class="dropdown-item disabled">Preferences</a></li>
            <li>
              <hr class="dropdown-divider" />
            </li>

            <li>
              <a class="dropdown-item" (click)="logout()" role="button">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
