import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ControlRoutes } from "./pages/routes";
import { LoginComponent } from "./login/containers/login/login.component";

const routes: Routes = [
  {
    path: ControlRoutes.Login,
    component: LoginComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
