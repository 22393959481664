<app-control-center-modal
  modalTitle="Set customer ID"
  (buttonClose)="logout(true)"
  (buttonSaveChanges)="saveChanges()"
  ><input
    class="form-control form-control-lg"
    type="text"
    placeholder="customer Id"
    aria-label="customer Id example"
    [formControl]="customerId"
/></app-control-center-modal>
