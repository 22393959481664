<!-- Button trigger modal  hidden because we want to show after the user arrived -->
<button
  type="button"
  id="showModalButton"
  class="d-none"
  (click)="createModal(sysOpModal)"
>
  Add modal
</button>

<!-- Modal -->
<div
  #sysOpModal
  id="sysOpModal"
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">{{ modalTitle }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <button
          id="closeModalButton"
          type="button"
          (click)="close()"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button type="button" class="btn btn-primary" (click)="saveChanges()">
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
