import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AuthService } from "src/app/login/services/auth.service";
import { LocalStorageService } from "../../services";
import { Router } from "@angular/router";
import * as bootstrap from "bootstrap";
import { ModalService } from "src/app/shared/services";

@Component({
  selector: "app-sysop",
  templateUrl: "./sysop.component.html",
  styleUrls: ["./sysop.component.scss"],
})
export class SysopComponent implements OnInit {
  customerId: FormControl = new FormControl("", [Validators.required]);

  constructor(
    private router: Router,
    private authHTTP: AuthService,
    private modalService: ModalService,
    private localStorageService: LocalStorageService
  ) {}
  ngOnInit(): void {
    //Show modal

    this.modalService.openModal();

    this.customerId.valueChanges.subscribe((values) => {
      console.log(this.customerId, values);
    });
  }

  saveChanges(): void {
    this.localStorageService.setItem("customerId", this.customerId.value);
    this.router.navigate([""]);
    this.modalService.sysOpModal?.hide();
  }

  openModal(element: any) {
    this.modalService.sysOpModal = new bootstrap.Modal(element, {});
    this.modalService.sysOpModal?.show();
  }

  logout(logOut: boolean): void {
    //Hide modal
    this.localStorageService.clear();
    this.authHTTP.logout();
  }
}
