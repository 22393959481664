import { AfterViewInit, Component } from "@angular/core";
import { MasonryService } from "src/app/shared/services/masonry.service";

@Component({
  selector: "app-control-center",
  templateUrl: "./control-center.component.html",
  styleUrls: ["./control-center.component.scss"],
})
export class ControlCenterComponent implements AfterViewInit {
  title: string = "Control Center";

  constructor(private _masonry: MasonryService) {}

  ngAfterViewInit(): void {
    this._masonry.initMasonry();
  }

  onClick(): void {
    console.log("click");
  }
}
