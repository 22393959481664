import { Component } from "@angular/core";
import { TransactionAlertDto } from "../../models";

@Component({
  selector: "app-tile-alerts-transactions",
  templateUrl: "./tile-alerts-transactions.component.html",
  styleUrls: ["./tile-alerts-transactions.component.scss"],
})
export class TileAlertsTransactionsComponent {
  alerts: TransactionAlertDto[] = [
    {
      hasIcon: true,
      alertName: "Check Cleared",
      alertDescription: "Check #123456789 has cleared in My Checking - 1234",
      duration: "2s",
    },
    {
      hasIcon: true,
      alertName: "Large Withdrawal",
      alertDescription:
        "Your current balance for Rewards Checking - 1234 is $10,00.00",
      duration: "1w",
    },
    {
      hasIcon: false,
      alertName: "Large Withdrawal",
      alertDescription:
        "Your current balance for Rewards Checking - 1234 is $10,00.00",
      duration: "2w",
    },
  ];
}
