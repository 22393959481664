<div class="w-100 ps-3 pb-3 pe-0 head bg-white">
  <div class="d-flex flex-column justify-content-start">
    <h4 class="mt-2 mb-0 pb-0 pt-1">{{ title }}</h4>
    <div
      *ngIf="hasBreadcrumbs"
      style="--bs-breadcrumb-divider: '>'"
      aria-label="breadcrumb"
    >
      <ol class="breadcrumb mb-0 mt-1">
        <li *ngFor="let item of items; last as isLast" class="breadcrumb-item">
          <a [href]="item.route">
            {{ item.label }}
          </a>
        </li>
      </ol>
    </div>
  </div>
</div>
