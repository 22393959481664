import { Component, Input } from "@angular/core";

@Component({
  selector: "app-sub-tile-balance",
  templateUrl: "./sub-tile-balance.component.html",
  styleUrls: ["./sub-tile-balance.component.scss"],
})
export class SubTileBalanceComponent {
  @Input({ required: true }) subTitle = "";
  @Input({ required: true }) balance = 0;
}
