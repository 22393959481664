import { Component } from "@angular/core";

@Component({
  selector: "app-tile-account-summary",
  templateUrl: "./tile-account-summary.component.html",
  styleUrls: ["./tile-account-summary.component.scss"],
})
export class TileAccoutSummaryComponent {
  hideAccount(text: string): string {
    if (text.length <= 4) {
      return "The account number is not valid";
    }

    const textToReplace = text.substring(0, text.length - 4);
    const textToShow = text.substring(text.length - 4, text.length);
    const replacedText = "#".repeat(textToReplace.length);

    return replacedText + textToShow;
  }
}
