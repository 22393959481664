import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./containers/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationInterceptor } from "../interceptors/authentication.interceptor";
import { PagesModule } from "../pages/pages.module";
import { SharedModule } from "../shared/shared.module";
import { ModalSendOtpComponent } from "./components/modal-send-otp/modal-send-otp.component";
import { ModalVerifyOtpComponent } from "./components/modal-verify-otp/modal-verify-otp.component";

@NgModule({
  declarations: [
    LoginComponent,
    ModalSendOtpComponent,
    ModalVerifyOtpComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PagesModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ],
})
export class LoginModule {}
