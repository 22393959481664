import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-modal-verify-otp",
  templateUrl: "./modal-verify-otp.component.html",
  styleUrls: ["./modal-verify-otp.component.scss"],
})
export class ModalVerifyOtpComponent implements OnInit {
  form!: FormGroup;

  @Input() submitting: boolean = false;
  @Output() closeModal = new EventEmitter<any | undefined>();
  @Output() backModal = new EventEmitter<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.setForm();
  }

  private setForm(): void {
    this.form = this.fb.group({
      otp: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      rememberDevice: [false],
    });
  }

  onCloseClick(): void {
    this.closeModal.emit();
  }

  onResendClick(): void {
    this.backModal.emit();
  }

  onSubmit(): void {
    this.closeModal.emit(this.form.value);
  }
}
