<nav
  class="side-nav-content bg-body-tertiary app-sidebar-menu"
  [ngClass]="menuStatus ? 'opened-menu' : 'closed-menu'"
>
  <!-- Header -->
  <div class="top-container">
    <div class="logo" [ngStyle]="{ width: menuStatus ? '240px' : '72px' }">
      <img src="https://approval-devtest.zis.com/assets/Logo.png" alt="mdo" />
    </div>

    <div class="title">
      <p
        style="color: #1e3860; font-size: 24px; font-weight: 600"
        [ngStyle]="{ display: menuStatus ? 'flex' : 'none' }"
      >
        Zenith Bold
      </p>
    </div>
  </div>

  <!-- List -->
  <ul class="nav-list" [ngClass]="menuStatus ? 'opened-menu' : 'closed-menu'">
    <li
      class="nav-list-item"
      [ngClass]="currentState === 'controlCenter' ? 'selected' : ''"
      (click)="onClick('/')"
      role="button"
    >
      <a class="link-item">
        <i class="icon-item bi bi-grid"></i>
        <span class="text-item">Control&nbsp;Center</span>
      </a>
    </li>
    <li
      class="nav-list-item"
      [ngClass]="currentState === 'clients' ? 'selected' : ''"
    >
      <a class="link-item disabled">
        <i class="icon-item bi bi-people"></i>
        <span class="text-item"> Clients</span>
      </a>
    </li>
  </ul>

  <!-- Footer -->
  <div
    class="footer"
    [ngClass]="menuStatus ? 'footer-opened' : 'footer-closed'"
  >
    <button class="btn" type="button" (click)="toggle()">
      <i
        [ngClass]="
          menuStatus
            ? 'bi bi-chevron-double-left'
            : 'bi bi-chevron-double-right'
        "
      ></i>
    </button>
  </div>
</nav>
