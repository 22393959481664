import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/login/services/auth.service";
import { Modal } from "bootstrap";
import { UserInfoData } from "../../models/auth-response";
import { OTPSend } from "../../models/otp-send";
import { ToastService } from "src/app/_core/services";
import { HttpErrorResponse } from "@angular/common/http";
import { OTPValidationResponse } from "../../models/otp-validate";
import { LocalStorageService } from "src/app/pages/services";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  todayDate: Date = new Date();
  errorMessage: string = "";
  submitting: boolean = false;
  timeoutMessage: string = "";
  submitVisible: boolean = true;
  passwordType: string = "password";
  userData!: UserInfoData;
  methodModal!: Modal;
  otpModal!: Modal;
  otpAttempt: number = 0;
  otpSubmitting!: boolean;

  constructor(
    private fb: FormBuilder,
    private authHTTP: AuthService,
    private _toast: ToastService,
    private storage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.timeoutMessage = this.authHTTP.loginTimoutMessage;

    if (this.authHTTP.isLoggedIn) {
      console.log("SSO already logged in");
      this.authHTTP.loginSSO();
    }
  }

  private setForm(): void {
    this.form = this.fb.group({
      userName: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  onSubmit(): void {
    this.submitting = true;
    this.submitVisible = false;
    this.errorMessage = "";

    this.authHTTP.login(this.form.value).subscribe({
      next: async (userData: UserInfoData) => {
        if (!!userData) {
          this.submitVisible = true;
          this.submitting = false;

          const response = this.authHTTP.authResponse();

          if (response?.userInfo?.deviceInfo.advice !== 0) {
            this.userData = userData;

            this.methodModal = new Modal("#methodModal", {
              keyboard: false,
              backdrop: "static",
            });
            this.methodModal.show();
          } else {
            await this.authHTTP.validatePasswordsChanges(response);
            this.authHTTP.setAuthData(response, true);

            this.runRefreshTokenTimer(response.expiresIn ?? 3600);
          }
        }
      },
      error: (err) => {
        this.errorMessage = "Login Failed";
        this.submitVisible = true;
        this.submitting = false;
        console.log(err);
      },
    });
  }

  closeMethodModal(selectedMethod: number | undefined): void {
    if (!!selectedMethod) {
      this.authHTTP.getOTP(selectedMethod).subscribe({
        next: (success: boolean) => {
          if (success) {
            this.methodModal.hide();
            this.methodModal = undefined!;

            this.otpModal = new Modal("#otpModal", {
              keyboard: false,
              backdrop: "static",
            });
            this.otpModal.show();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.methodModal.hide();
          this.methodModal = undefined!;

          this._toast.showToast("Error!", err.message);
        },
      });
    } else {
      this.methodModal.hide();
      this.methodModal = undefined!;
    }
  }

  closeOtpModal(otpData: OTPSend | undefined): void {
    if (!!otpData) {
      this.otpSubmitting = true;
      this.authHTTP.verifyOTP(otpData).subscribe({
        next: async (response: OTPValidationResponse) => {
          if (response.success) {
            this.otpAttempt = 0;
            this.otpModal.hide();
            this.otpModal = undefined!;

            await this.authHTTP.validatePasswordsChanges(response.authInfo);
            this.authHTTP.setAuthData(response.authInfo, true);

            this.runRefreshTokenTimer(response.authInfo.expiresIn ?? 3600);
          } else {
            if (this.otpAttempt === 2) {
              this.otpAttempt = 0;
              this._toast.showToast(
                "Error!",
                "The Max. Number of attempts has been reached"
              );
              this.otpModal.hide();
              this.otpModal = undefined!;
            } else {
              this.otpAttempt++;
              this._toast.showToast("Error!", response.message);
            }
          }

          this.otpSubmitting = undefined!;
        },
        error: (err: HttpErrorResponse) => {
          console.log(err);
          this._toast.showToast("Error!", err.message);
        },
      });
    } else {
      this.otpModal.hide();
      this.otpModal = undefined!;
    }
  }

  backMethodModal(): void {
    this.otpAttempt = 0;
    this.otpModal.hide();
    this.otpModal = undefined!;

    this.methodModal = new Modal("#methodModal", {
      keyboard: false,
      backdrop: "static",
    });
    this.methodModal.show();
  }

  runRefreshTokenTimer(seconds: number): void {
    let interval = seconds * 0.75;
    this.storage.setItem("interval", interval.toString());
  }

  switchPasswordType(): void {
    this.passwordType = this.passwordType == "password" ? "text" : "password";
  }
}
