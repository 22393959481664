<app-tile title="Account Summary" buttonText="View All Details">
  <hr />
  <div class="d-flex flex-column">
    <div class="d-flex flex-column number-column">
      <span> Account Number </span
      ><span class="number-text">{{ hideAccount("123456789123") }} </span>
    </div>
    <div class="d-flex flex-column number-column">
      <span>Routing Number</span
      ><span class="number-text">{{ hideAccount("123456789") }}</span>
    </div>
  </div>
</app-tile>
