import { AfterViewInit, Component, OnInit } from "@angular/core";
import { MasonryService } from "src/app/shared/services";
import { ModalPasswordService } from "src/app/_core/services";

@Component({
  selector: "app-password-management",
  templateUrl: "./password-management.component.html",
  styleUrls: ["./password-management.component.scss"],
})
export class PasswordManagementComponent implements OnInit, AfterViewInit {
  title: string = "Password Management";

  constructor(
    private _masonry: MasonryService,
    private _modalPassword: ModalPasswordService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._masonry.initMasonry();
  }

  openPasswordModal(modalType: string): void {
    this._modalPassword.show(modalType);
  }
}
