import { Injectable } from "@angular/core";
import * as Masonry from "masonry-layout";

@Injectable({
  providedIn: "root",
})
export class MasonryService {
  private masonryContainer!: Masonry;
  constructor() {}

  initMasonry(): void {
    const elem = document.querySelector(".masonry-container");

    this.masonryContainer = new Masonry(elem!, {
      percentPosition: true,
      columnWidth: ".masonry-sizer",
    });
  }

  updateLayout(): void {
    setTimeout(() => {
      this.masonryContainer.layout!();
    }, 300);
  }
}
