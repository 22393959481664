import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BankTransactionsPostingDetails } from "../models";
import { Observable } from "rxjs";
import { HttpBaseService } from "src/app/shared/services";

@Injectable({
  providedIn: "root",
})
export class BankingTransactionsService extends HttpBaseService {
  constructor(private _http: HttpClient) {
    super(_http, "v2");
  }

  getTransactions(
    code: string,
    period: string,
    clientId: string
  ): Observable<BankTransactionsPostingDetails[]> {
    return this._http.get<BankTransactionsPostingDetails[]>(
      this.apiUrl +
        `reports/AccountDetails?clientNumber=${clientId}&fye=2023-12-31&code=${code}&period=${period}`
    );
  }
}
