import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserInfoData } from "../../models/auth-response";
import { OTPOptions } from "../../models/otp-options";

@Component({
  selector: "app-modal-send-otp",
  templateUrl: "./modal-send-otp.component.html",
  styleUrls: ["./modal-send-otp.component.scss"],
})
export class ModalSendOtpComponent implements OnInit {
  form!: FormGroup;
  submitting: boolean = false;

  options: OTPOptions[] = [];

  @Input() userInfo!: UserInfoData;
  @Output() closeModal = new EventEmitter<number | undefined>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.maskUserData();
    this.setForm();
  }

  private maskUserData(): void {
    if (this.userInfo.email!!) {
      const email = this.userInfo.email?.slice(0, 4).concat("*******");
      this.options.push({ label: `Email (${email})`, value: 2, id: "email" });
    }

    if (this.userInfo.phoneNumber!!) {
      const phoneNumber = "***-***".concat(
        this.userInfo.phoneNumber?.slice(-4)
      );
      this.options.push(
        { label: `Text Message (${phoneNumber})`, value: 1, id: "sms" },
        { label: `Phone Call (${phoneNumber})`, value: 4, id: "call" }
      );
    }
  }

  private setForm(): void {
    this.form = this.fb.group({
      selectedMethod: ["", Validators.required],
    });
  }

  onCloseClick(): void {
    this.closeModal.emit();
  }

  onSubmit(): void {
    this.submitting = true;
    this.closeModal.emit(this.form.value.selectedMethod);
  }
}
