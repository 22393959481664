import { Component, Input } from "@angular/core";
import { TransactionAlertDto } from "src/app/pages/models";

@Component({
  selector: "app-alert-description-content",
  templateUrl: "./alert-description-content.component.html",
  styleUrls: ["./alert-description-content.component.scss"],
})
export class AlertDescriptionContentComponent {
  @Input() item?: TransactionAlertDto;
}
