<app-header-title [hasBreadcrumbs]="true"> </app-header-title>
<div class="container-fluid px-3 pt-3">
  <div class="row g-3 masonry-container">
    <div class="col-1 m-0 masonry-sizer"></div>
    <!-- keep all the component tiles below this div -->

    <app-tile-bank-account-detail
      class="col-12"
      [clientId]="clientId"
      [accountId]="accountId"
    ></app-tile-bank-account-detail>
    <app-tile-transactions
      class="col-12"
      [clientId]="clientId"
    ></app-tile-transactions>
  </div>
</div>
