import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MasonryService } from "../../services/masonry.service";

@Component({
  selector: "app-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"],
})
export class TileComponent implements OnChanges {
  @Input({ required: true }) title = "";
  @Input() buttonText: string | null = null;
  @Input() hasDivider = false;
  @Input() isLoading: boolean = false;
  @Input() isMock: boolean = false;
  @Input() buttonState: boolean = false;

  @Output() buttonClick = new EventEmitter<void>();

  constructor(private _masonry: MasonryService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["isMock"]! && changes["isLoading"]!) {
      this._masonry.updateLayout();
    }
  }
}
