<div class="row-container" [ngClass]="!isMock ? 'placeholder-glow' : ''">
  <p class="m-0">
    <span class="placeholder rounded col-7 me-1"></span>
    <span class="placeholder rounded col-4"></span>
    <span class="placeholder rounded col-12"></span>
  </p>
  <hr class="mt-2 mb-1" />
  <p class="m-0">
    <span class="placeholder rounded col-7 me-1"></span>
    <span class="placeholder rounded col-4"></span>
    <span class="placeholder rounded col-12"></span>
  </p>
  <hr class="mt-2 mb-1" />
  <p class="mt-0 mb-2">
    <span class="placeholder rounded col-7 me-1"></span>
    <span class="placeholder rounded col-4"></span>
    <span class="placeholder rounded col-12"></span>
  </p>
</div>
