import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { EMPTY, Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../login/services/auth.service";
import { LoggerService, ToastService } from "../_core/services";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private loggerService: LoggerService,
    private _toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = `Bearer ${
      sessionStorage.getItem("token") ?? this.cookieService.get("token")
    }`;
    const sessionKey =
      sessionStorage.getItem("sessionKey") ??
      this.cookieService.get("sessionKey");
    const sessionId =
      sessionStorage.getItem("sessionId") ??
      this.cookieService.get("sessionId");
    const hash =
      sessionStorage.getItem("hash") ?? this.cookieService.get("hash");

    if (
      !request.url.endsWith("auth/token") &&
      !request.url.endsWith("auth/GetOtp") &&
      !request.url.endsWith("auth/ValidateOtp") &&
      !request.url.endsWith("auth/ping") &&
      !request.url.endsWith("auth/refresh") &&
      !request.url.endsWith("auth/logout") &&
      !request.url.endsWith("auth/changePassword/zenith") &&
      !request.url.endsWith("auth/changePassword/identity") &&
      !request.url.endsWith("Log/control-center") //avoid made a request on logger
    ) {
      if (!authToken || !sessionKey || !sessionId || !hash) {
        this._toastService.showToast(
          "Warning!",
          "For security reasons, your call has been aborted."
        );

        return EMPTY;
      }

      this.authService.setCookiePing();
      this.authService.authPing();
      this.loggerService.LogInformation(request.url, request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: authToken,
        "Content-Type": "application/json",
        "X-ZIS-SessionKey": sessionKey,
        "X-ZIS-SessionID": sessionId,
        "X-ZIS-Hash": hash ?? "",
      },
    });

    return next.handle(request);
  }
}
