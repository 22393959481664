<div class="card">
  <div class="top-container">
    <h4 class="ms-4 mt-4 me-4 title">
      {{ title }}
    </h4>
    <ng-content select="[dropdown]"></ng-content>
  </div>
  <hr *ngIf="hasDivider" />
  <div class="card-body ps-4 pt-0 pe-4 pb-0">
    <ng-container *ngIf="isLoading">
      <app-placeholder-tile [isMock]="isMock"></app-placeholder-tile>
    </ng-container>

    <div [hidden]="isLoading">
      <ng-content></ng-content>
    </div>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="ms-4 me-4 button-link" *ngIf="buttonText !== null">
      <button
        type="button"
        class="btn btn-link link-underline link-underline-opacity-0"
        [disabled]="buttonState"
        (click)="buttonClick.emit()"
      >
        {{ buttonText }}
      </button>
    </div>
    <hr *ngIf="hasDivider" class="pb-3" />
  </ng-container>
</div>
