import { Injectable, signal, Signal } from "@angular/core";
import {
  RealEstateHoldingsTileDTO,
  RealEstateType,
  ApprovalsTileDTO,
} from "../models";

@Injectable({
  providedIn: "root",
})
export class MockedDataService {
  dataRealEstateHoldingsTile: Signal<RealEstateHoldingsTileDTO[]> = signal<
    RealEstateHoldingsTileDTO[]
  >([
    {
      id: "00402",
      name: "Primary Residence",
      address: "4525 252ND AVE. SE., ISSAQUAH, WA",
      value: 1776331.12,
      type: RealEstateType.house,
    },
    {
      id: "00403",
      name: "Investment Property",
      address: "17218 53RD STREET SE, SNOHOMISH, WA",
      value: 1500000.0,
      type: RealEstateType.department,
    },
    {
      id: "00404",
      name: "Investment Property",
      address: "1060 RAYMOND AVE, GLENDALE, CA",
      value: 516735.63,
      type: RealEstateType.beach,
    },
  ]);

  dataApprovalsTile: Signal<ApprovalsTileDTO[]> = signal<ApprovalsTileDTO[]>([
    {
      id: "0040",
      name: "Francis Marion",
      accountTitle: "Comerica Acct",
      items: 13,
    },
    {
      id: "0030",
      name: "Mary Hartwell",
      accountTitle: "Comerica Acct",
      items: 8,
    },
    {
      id: "0050",
      name: "Anna Strong",
      accountTitle: "Comerica Acct",
      items: 2,
    },
  ]);

  constructor() {}

  //the idea for this method is return all the fake data
  getMockedData<T>(type: string): T {
    switch (type) {
      case "dataRealEstateHoldingsTile":
        return this.dataRealEstateHoldingsTile() as T;

      case "dataApprovalsTile":
        return this.dataApprovalsTile() as T;

      default:
        return [] as T;
    }
  }
}
