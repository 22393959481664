<app-tile title="Debt" [hasDivider]="true" [isLoading]="isLoading">
  <app-sub-tile-balance
    subTitle="Total Debt"
    [balance]="balance"
  ></app-sub-tile-balance>
  <div class="row-container">
    <ng-container *ngFor="let item of items">
      <app-row-data>
        <div class="col-6">
          <span class="fw-semibold">{{ item.MASTER_TITLE }}</span>
          <p class="fw-light m-0 id-text">{{ item.MASTER }}</p>
        </div>
        <div class="col-4 text-end">
          <span class="">
            {{ item.ENDING_BALANCE | currency }}
          </span>
        </div>
      </app-row-data>
    </ng-container>
  </div>
</app-tile>
