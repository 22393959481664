<app-tile title="Transactions" [isLoading]="isLoading">
  <div class="dropdown col-12 col-sm-8 mb-2 mt-4 px-4" dropdown>
    <div class="d-grid d-sm-flex flex-row-reverse gap-3">
      <!-- Page Dropdown -->
      <ng-select
        [(ngModel)]="selectedPageOption"
        class="col-12 col-sm-4 custom"
        name="pageOptions"
        [clearable]="false"
        [searchable]="false"
        [disabled]="disabled"
        (change)="onChangePageOption($event)"
      >
        <ng-option
          *ngFor="let page of pages"
          [value]="page"
          [disabled]="isDisabled(page)"
        >
          {{ page.option }}
        </ng-option>
      </ng-select>
      <!-- Period Dropdown -->
      <ng-select
        [(ngModel)]="selectedPeriodOption"
        class="col-12 col-sm-8 custom"
        name="periodOptions"
        [clearable]="false"
        [searchable]="false"
        (change)="onChangePeriodOption($event)"
      >
        <ng-option *ngFor="let period of periods" [value]="period">
          <!-- <input type="radio"> -->
          {{ period.option }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-hover mt-1">
      <thead>
        <tr>
          <th scope="col">Transaction Date</th>
          <th scope="col">Journal</th>
          <th scope="col">Full Reference</th>
          <th scope="col">Description</th>
          <th scope="col" class="text-end">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of transactions
              | paginate
                : {
                    id: 'pages',
                    itemsPerPage: pageSize,
                    currentPage: currentPage,
                    totalItems: transactions.length
                  }
          "
        >
          <td class="date">{{ item.TRANSACTION_DATE | date }}</td>
          <td style="width: 5%">{{ item.JOURNAL }}</td>
          <td style="width: 7%">{{ item.FULL_REFERENCE }}</td>
          <td style="width: 65%">
            <span class="" title="{{ item.DESCRIPTION }}">
              {{ item.DESCRIPTION }}
            </span>
          </td>
          <td style="width: 13%" class="text-end">
            {{ item.AMOUNT | currency }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Pagination -->
    <div *ngIf="!disabled" class="table-footer">
      <pagination-controls
        id="pages"
        previousLabel=""
        nextLabel=""
        (pageChange)="onPageChange($event)"
      >
      </pagination-controls>
    </div>
  </div>
</app-tile>
