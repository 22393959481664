<!-- Generate OTP Code -->

<div class="modal-content">
  <!-- Header -->
  <div class="modal-header">
    <h5 class="modal-title">Verify Security Code</h5>
    <button
      type="button"
      class="btn-close"
      (click)="onCloseClick()"
      [disabled]="submitting"
    ></button>
  </div>
  <!-- Content -->
  <div class="modal-body">
    <form [formGroup]="form">
      <h6 class="mb-3">
        Select your preferred OTP method from the options below:
      </h6>

      <div class="form-check" *ngFor="let option of options">
        <input
          class="form-check-input"
          type="radio"
          [value]="option.value"
          formControlName="selectedMethod"
          [id]="option.id"
          [disabled]="submitting"
        />
        <label class="form-check-label" [for]="option.id">
          {{ option.label }}
        </label>
      </div>
    </form>
  </div>
  <!-- Footer -->
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [disabled]="form.invalid || submitting"
    >
      <ng-template [ngIf]="submitting">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </ng-template>
      Send OTP
    </button>
  </div>
</div>
