import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { map, Observable } from "rxjs";
import {
  AccountsSubCodes,
  Code,
  InvestmentTileDto,
  OverDrawnAccountDto,
} from "../models";
import {
  BankAccountDTO,
  BankAccountSubCodes,
} from "../models/bank-account-dto";
import { HttpBaseService } from "src/app/shared/services";

@Injectable({
  providedIn: "root",
})
export class BankingService extends HttpBaseService {
  accountsSubCodesTotalSignal = signal<number>(0);
  accountsSubCodesSignal = signal<AccountsSubCodes[]>([]);
  accountsSubCodesLoadingSignal = signal<boolean>(false);

  constructor(private _http: HttpClient) {
    super(_http, "v2");
  }

  getOverDrawnAccountDto(take: number) {
    this.accountsSubCodesLoadingSignal.set(true);
    return this._http
      .get<OverDrawnAccountDto>(this.apiUrl + "reports/OverDrawnAccounts")
      .subscribe({
        next: (result) => {
          const items = result["Accounts (Sub Codes)"];

          this.accountsSubCodesTotalSignal.set(items.length);

          //zero take all
          if (take === 0) {
            this.accountsSubCodesSignal.set(items);
          } else {
            const filtered = items.slice(0, take);
            this.accountsSubCodesSignal.set(filtered);
          }

          this.accountsSubCodesLoadingSignal.set(false);
        },
        error: (error) => {
          console.error("error", error);
          this.accountsSubCodesLoadingSignal.set(false);
        },
        complete: () => {
          this.accountsSubCodesLoadingSignal.set(false);
        },
      });
  }

  bankAccounts = signal<BankAccountSubCodes[]>([]);

  getBankAccounts(clientNumber: string): Observable<BankAccountSubCodes[]> {
    return this._http
      .get<BankAccountDTO>(this.apiUrl + "reports/BankBalances", {
        params: { clientNumber: clientNumber },
      })
      .pipe(
        map((resp: any) => {
          this.bankAccounts.set(
            resp["Banks & Credit Lines w/MICR"] as BankAccountSubCodes[]
          );
          return resp["Banks & Credit Lines w/MICR"];
        })
      );
  }

  getBankInvestmentData(clientNumber: string): Observable<Code[]> {
    return this._http
      .get<InvestmentTileDto>(this.apiUrl + "reports/TileMasters", {
        params: { clientNumber: clientNumber, tileConfig: "INVESTMENTS" },
      })
      .pipe(
        map((resp: any) => {
          return resp["Master Codes"]; //TODO Improve this
        })
      );
  }

  getDebitData(clientNumber: string): Observable<Code[]> {
    return this._http
      .get<InvestmentTileDto>(this.apiUrl + "reports/TileMasters", {
        params: { clientNumber: clientNumber, tileConfig: "DEBT" },
      })
      .pipe(
        map((resp: any) => {
          return resp["Master Codes"]; //TODO Improve this
        })
      );
  }
}
