import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/login/services";
import { ToastService } from "src/app/_core/services";
import {
  matchValidator,
  numberValidator,
  lowerCaseValidator,
  upperCaseValidator,
  specialValidator,
  beginEndNumberValidator,
} from "../../utils/password-validators";
import { AuthResponse } from "src/app/login/models/auth-response";

@Component({
  selector: "app-modal-change-password",
  templateUrl: "./modal-change-password.component.html",
  styleUrls: ["./modal-change-password.component.scss"],
})
export class ModalChangePasswordComponent implements OnInit {
  form!: FormGroup;
  submitting: boolean = false;

  currentPasswordType: string = "password";
  newPasswordType: string = "password";
  confirmPasswordType: string = "password";

  @Input() title!: string;
  @Input() username!: string;
  @Input() type!: string;
  @Input() forceData!: AuthResponse | undefined;
  @Output() closeModal = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private _authHTTP: AuthService,
    private _toast: ToastService
  ) {}

  ngOnInit() {
    this.setForm();
  }

  private setForm(): void {
    this.form = this.fb.group(
      {
        currentPassword: ["", Validators.required],
        newPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(13),
            Validators.maxLength(32),
            numberValidator(),
            lowerCaseValidator(),
            upperCaseValidator(),
            specialValidator(),
            beginEndNumberValidator(),
          ],
        ],
        confirmPassword: ["", Validators.required],
      },
      {
        validators: [matchValidator()],
      }
    );

    if (!this.username) {
      this.form.addControl(
        "zenithUsername",
        this.fb.control("", Validators.required)
      );
    }
  }

  get newPassword() {
    return this.form.get("newPassword");
  }

  get confirmPassword() {
    return this.form.get("confirmPassword");
  }

  onCloseClick(): void {
    this.closeModal.emit();
  }

  onSubmit(): void {
    this.submitting = true;

    switch (this.type) {
      case "zenith":
        this._authHTTP
          .changeZenithPassword(this.form.value, this.forceData)
          .subscribe({
            next: () => {
              this.submitting = undefined!;

              this.closeModal.emit();

              this._toast.showToast("Success!", "Password updated");
            },
            error: (err: HttpErrorResponse) => {
              this.submitting = undefined!;
              console.log(err);
              this._toast.showToast("Error!", err.message);
            },
          });

        break;

      case "axos":
        this._authHTTP
          .changeAxosPassword(this.form.value, this.forceData)
          .subscribe({
            next: () => {
              this.submitting = undefined!;

              this.closeModal.emit();

              this._toast.showToast("Success!", "Password updated");
            },
            error: (err: HttpErrorResponse) => {
              this.submitting = undefined!;
              console.log(err);
              this._toast.showToast("Error!", err.message);
            },
          });

        break;

      default:
        break;
    }
  }

  switchPasswordType(inputType: string): string {
    return inputType == "password" ? "text" : "password";
  }
}
