<app-header-title [title]="title"></app-header-title>
<div class="container-fluid px-3 pt-3">
  <div class="row g-3 masonry-container">
    <div class="col-1 m-0 masonry-sizer"></div>
    <!-- keep all the component tiles below this div -->

    <div class="col-12 col-sm-6">
      <div class="d-grid col-6 mx-auto">
        <button
          class="btn btn-primary"
          type="button"
          (click)="openPasswordModal('zenith')"
        >
          Change Zenith Password
        </button>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="d-grid col-6 mx-auto">
        <button
          class="btn btn-primary"
          type="button"
          (click)="openPasswordModal('axos')"
        >
          Change Axos Password
        </button>
      </div>
    </div>
  </div>
</div>
