import { Injectable } from "@angular/core";
import { Modal } from "bootstrap";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";
import { AuthResponse } from "src/app/login/models/auth-response";

@Injectable({
  providedIn: "root",
})
export class ModalPasswordService {
  private modalPassword!: Modal;
  private modalType!: string;
  private modalUsername!: string;
  private modalPasswordTitle!: string;
  private modalForceData: AuthResponse | undefined;

  public isClosed$!: BehaviorSubject<boolean>;

  constructor(private _cookieService: CookieService) {}

  show(modalType: string, forceData?: AuthResponse): void {
    this.isClosed$ = new BehaviorSubject(false);

    this.modalType = modalType;
    this.modalForceData = forceData;

    switch (this.modalType) {
      case "zenith":
        this.modalUsername =
          this.modalForceData?.zenithUserName ??
          this._cookieService.get("zenithUserName");
        this.modalPasswordTitle = "Change Zenith Password";

        this.modalPassword = new Modal("#modalPassword", {
          keyboard: false,
          backdrop: "static",
        });

        this.modalPassword.show();
        break;

      case "axos":
        this.modalUsername =
          this.modalForceData?.userName ?? this._cookieService.get("userName");
        this.modalPasswordTitle = "Change Axos Password";

        this.modalPassword = new Modal("#modalPassword", {
          keyboard: false,
          backdrop: "static",
        });

        this.modalPassword.show();
        break;

      default:
        break;
    }
  }

  close(): void {
    if (this.modalPassword!!) {
      this.modalPassword.hide();
      this.modalPassword = undefined!;

      setTimeout(() => {
        this.isClosed$.complete();
      }, 300);
    }
  }

  get modal(): any {
    return this.modalPassword;
  }

  get type(): string {
    return this.modalType;
  }

  get username(): string {
    return this.modalUsername;
  }

  get title(): string {
    return this.modalPasswordTitle;
  }

  get forceData(): AuthResponse | undefined {
    return this.modalForceData;
  }
}
