import { Injectable } from "@angular/core";
import { Toast } from "bootstrap";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private toast!: Toast;

  private title!: string;
  private message!: string;

  constructor() {}

  get toastTitle(): string {
    return this.title;
  }

  get toastMessage(): string {
    return this.message;
  }

  showToast(title: string, message: string): void {
    this.title = title;
    this.message = message;

    this.toast = new Toast("#toast");
    this.toast.show();
  }
}
