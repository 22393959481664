import { AfterViewInit, Component, Input } from "@angular/core";
import { MasonryService } from "src/app/shared/services/masonry.service";

@Component({
  selector: "app-client-transactions",
  templateUrl: "./client-transactions.component.html",
  styleUrls: ["./client-transactions.component.scss"],
})
export class ClientTransactionsComponent implements AfterViewInit {
  @Input() clientId!: string;
  @Input() accountId!: string;

  constructor(private _masonry: MasonryService) {}

  ngAfterViewInit(): void {
    this._masonry.initMasonry();
  }
}
