import { Component, Input, OnInit } from "@angular/core";
import { MasonryService } from "src/app/shared/services/masonry.service";
import { BankAccountSubCodes } from "../../models/bank-account-dto";
import { BankingService } from "../../services";

@Component({
  selector: "app-tile-bank-account-detail",
  templateUrl: "./tile-bank-account-detail.component.html",
  styleUrls: ["./tile-bank-account-detail.component.scss"],
})
export class TileBankAccountDetailComponent implements OnInit {
  @Input() clientId!: string;
  @Input() accountId!: string;
  detailData: BankAccountSubCodes | undefined;

  constructor(
    private _bankingService: BankingService,
    private _masonry: MasonryService
  ) {}

  ngOnInit(): void {
    this._bankingService.getBankAccounts(this.clientId ?? "").subscribe({
      next: (accounts: BankAccountSubCodes[]) => {
        this.detailData = accounts.find(
          (account: BankAccountSubCodes) => account.CODE === this.accountId
        );
      },
      complete: () => {
        this._masonry.updateLayout();
      },
    });
  }
}
