import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tile-documents",
  templateUrl: "./tile-documents.component.html",
  styleUrls: ["./tile-documents.component.scss"],
})
export class TileDocumentsComponent implements OnInit {
  title: string = this.setTitleText();
  isLoading: boolean = true;

  ngOnInit(): void {
    this.title = this.setTitleText(23);
  }

  setTitleText(newValue: number = 0): string {
    return `Documents (${newValue})`;
  }
}
