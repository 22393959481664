import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApprovalsTileDTO } from "../../models/approvals-tile-dto";
import { MockedDataService } from "../../services";

@Component({
  selector: "app-tile-approvals",
  templateUrl: "./tile-approvals.component.html",
  styleUrls: ["./tile-approvals.component.scss"],
})
export class TileApprovalsComponent implements OnInit {
  dataItems: ApprovalsTileDTO[] = [];
  titleTile: string = this.setTitleText();
  buttonText: string = "Go to Approvals";

  constructor(private _dataMock: MockedDataService) {}

  ngOnInit(): void {
    this.dataItems =
      this._dataMock.getMockedData<ApprovalsTileDTO[]>("dataApprovalsTile");

    this.titleTile = this.setTitleText(27);
  }

  setTitleText(newValue: number = 0): string {
    return `Approvals (${newValue})`;
  }

  goToApprovals(): void {
    window.open(environment.approvalsUrl, "_blank");
  }
}
